import React, { useContext } from 'react';
import DOMPurify from 'dompurify';
import { CCard, CCardBody, CButton } from '@coreui/react';
import { useSelector } from 'react-redux';

import { removeHTMLFromString } from '../../../../../../utils';
import SimpleCopyButton from '../../../../../general/buttons/SimpleCopyButton';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';

const GTMNoPublishingRights = () => {
    const { stepsData, closeWizard } = useContext(SwitchTypeWizardContext);
    const activeAccount = useSelector(state => state.subscriber.activeAccount);
    const { currentContainer } = activeAccount.gtmInfo;
    const { publicId, userPermissions } = currentContainer;
    const gtmContainerAdmins = Array.isArray(userPermissions) ? userPermissions.filter(permission => permission.accountAccess.permission === 'admin') : [];

    let htmlMailMessage = `I have some changes in GTM that need to be published and I don't have publish access.`;
    htmlMailMessage += ` You'll find the changes in this container <b>${publicId}</b> and this workspace ${stepsData.gtmWorkspaceName}.`;
    htmlMailMessage += ` Can you go ahead and publish these changes to make them live?`;
    let mailMessage = removeHTMLFromString(htmlMailMessage);
    const sanitizedHtmlMailMessage = DOMPurify.sanitize(htmlMailMessage);

    return (
        <SwitchTypeWizardBody>
            <CCard className="account-gtm-no-publish">
                <CCardBody>
                    <h3>Oh no!</h3>
                    <p>
                        <strong>You don't have access to publish this container,</strong> so we can't make our changes live.
                    </p>
                    <p className="des">
                        We've updated Google Tag Manager with all of our changes, but they need to be published by an admin. Here is a list of admins
                        on the container. Ask them to publish our changes.
                    </p>
                    <div className="email">
                        {gtmContainerAdmins.map(({ emailAddress }) => (
                            <a key={emailAddress} href={`mailto:${emailAddress}?subject=Google Tag Manager Request&body=${mailMessage}`}>
                                {emailAddress}
                            </a>
                        ))}
                    </div>
                    <div className="script-code">
                        <div className="float-right d-flex align-items-center">
                            <SimpleCopyButton textToCopy={mailMessage} iconNew={true} />
                        </div>
                        <div className="note-copy">
                            <p dangerouslySetInnerHTML={{ __html: sanitizedHtmlMailMessage }}></p>
                        </div>
                    </div>
                    <CButton className="btn-navigation-step" onClick={closeWizard}>
                        I've sent them a request to publish, NEXT <i className="fal fa-angle-right"></i>
                    </CButton>
                </CCardBody>
            </CCard>
        </SwitchTypeWizardBody>
    );
};

export default GTMNoPublishingRights;
