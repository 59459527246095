import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCol, CRow } from '@coreui/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import 'tippy.js/dist/tippy.css';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import { DATE_RANGE_PRESETS, PERIOD_TYPES } from '../../../../../../constants';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../../../../general/dropdown';
import CLabel from '../../../../../migration/CLabel';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../../../migration/CInput';
import { useSelector } from 'react-redux';
import { Popup } from '../../../../../general/popup';

const DatePickerBox = ({ handleChangeDateRange, value, isSelectCompare, setDateBoxOpened }) => {
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const today = dayjs().tz(activeAccount.timeZone || undefined);
     const maxDate = today['$d'];

     const presetsToDisplay = [
          [DATE_RANGE_PRESETS.LAST_7_DAYS, DATE_RANGE_PRESETS.LAST_WEEK],
          [DATE_RANGE_PRESETS.LAST_14_DAYS, DATE_RANGE_PRESETS.THIS_MONTH],
          [DATE_RANGE_PRESETS.LAST_30_DAYS, DATE_RANGE_PRESETS.LAST_MONTH],
     ];

     const initialDateRange = {
          selection: {
               startDate: value.selection.startDate['$d'] || value.selection.startDate,
               endDate: value.selection.endDate['$d'] || value.selection.endDate,
               key: 'selection',
          },
     };

     if (value.compare) {
          initialDateRange.compare = {
               startDate: value.compare.startDate['$d'],
               endDate: value.compare.endDate['$d'],
               key: 'compare',
          };
     }

     const newValue = { ...value }
     delete newValue.selection
     delete newValue.compare

     const [focusedRange, setFocusedRange] = useState([0, 0]);
     const [dropdownOpen, setDropdownOpen] = useState(false);
     const [configData, setConfigDataState] = useState(newValue);
     const [calendarValue, setCalendarValue] = useState(initialDateRange);
     const ranges = Object.values(calendarValue).map((val) => val);

     // const noCompare = [
     //      REPORT_NAMES.EVENT_DETAILS,
     //      REPORT_NAMES.PAGE_VIEW_DETAILS,
     //      REPORT_NAMES.CONVERSION_EVENTS,
     //      REPORT_NAMES.FORM_SUBMISSION_EVENTS,
     //      REPORT_NAMES.SESSION_DETAILS,
     //      REPORT_NAMES.USER_DETAILS,
     //      REPORT_NAMES.PERSON_DETAILS,


     const customDateWrapperRef = useRef(null);
     // useOutsideHandling(customDateWrapperRef, toggleDateBox, 'mouseup');

     useEffect(() => {
          if (!configData.comparePeriod) return;

          const startDateSelection = dayjs(calendarValue.selection.startDate);
          const endDateSelection = dayjs(calendarValue.selection.endDate);
          const selectionDateSubtract = Math.floor((endDateSelection - startDateSelection) / (1000 * 3600 * 24));
          let startDateCompare, endDateCompare;

          switch (configData.periodType) {
               case PERIOD_TYPES.PREVIOUS_PERIOD:
                    const endDate = startDateSelection.subtract(1, 'day');
                    startDateCompare = endDate.subtract(selectionDateSubtract, 'day')['$d'];
                    endDateCompare = endDate['$d'];
                    break;
               case PERIOD_TYPES.PREVIOUS_YEAR:
                    startDateCompare = startDateSelection.subtract(1, 'year')['$d'];
                    endDateCompare = endDateSelection.subtract(1, 'year')['$d'];
                    break;
               default:
                    return;
          }

          if (!startDateCompare || !endDateCompare) return;

          setCalendarValue({
               ...calendarValue,
               compare: {
                    startDate: startDateCompare,
                    endDate: endDateCompare,
                    key: 'compare',
               },
          });
     }, [calendarValue.selection, configData.comparePeriod, configData.periodType]); // eslint-disable-line react-hooks/exhaustive-deps

     const setConfigData = (values) => {
          setConfigDataState((state) => ({ ...state, ...values }));
     };

     const onChangeDateRange = (ranges) => {
          const { selection, compare } = ranges;
          let newRanges = ranges;

          if (selection) {
               if (selection.startDate > maxDate) {
                    newRanges = {
                         selection: {
                              ...selection,
                              startDate: maxDate,
                         },
                    };
               } else if (selection.endDate > maxDate) {
                    newRanges = {
                         selection: {
                              ...selection,
                              endDate: maxDate,
                         },
                    };
               }
          } else if (compare) {
               if (compare.startDate > maxDate) {
                    newRanges = {
                         compare: {
                              ...compare,
                              startDate: maxDate,
                         },
                    };
               } else if (compare.endDate > maxDate) {
                    newRanges = {
                         compare: {
                              ...compare,
                              endDate: maxDate,
                         },
                    };
               }
          }

          setCalendarValue({ ...calendarValue, ...newRanges });
          setConfigData({ datePreset: DATE_RANGE_PRESETS.CUSTOM });

          if (compare) {
               setConfigData({ periodType: PERIOD_TYPES.CUSTOM });
          }
     };

     const handleSetComparePeriod = (compare) => {
          setConfigData({ comparePeriod: compare, periodType: PERIOD_TYPES.PREVIOUS_PERIOD });

          if (!compare) {
               const newDateRange = cloneDeep(calendarValue);
               delete newDateRange['compare'];
               setCalendarValue(newDateRange);

               if (focusedRange[0] === 1) {
                    // Reset focus range
                    setFocusedRange([0, 0]);
               }
          }
     };

     const onRangeFocusChange = (range) => {
          setFocusedRange(range);
     };

     const onChangePeriodType = (value) => {
          setConfigData({ periodType: value });
     };

     const onClickDatePreset = (preset) => {
          let dateRange;

          switch (preset) {
               case DATE_RANGE_PRESETS.TODAY:
                    dateRange = {
                         startDate: today['$d'],
                         endDate: today['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_7_DAYS:
                    dateRange = {
                         startDate: today.subtract(7, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_14_DAYS:
                    dateRange = {
                         startDate: today.subtract(14, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_30_DAYS:
                    dateRange = {
                         startDate: today.subtract(30, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_WEEK:
                    const lastWeek = today.subtract(1, 'week');
                    dateRange = {
                         startDate: lastWeek.startOf('week')['$d'],
                         endDate: lastWeek.endOf('week')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.THIS_MONTH:
                    dateRange = {
                         startDate: today.startOf('month')['$d'],
                         endDate: today.endOf('month')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_MONTH:
                    const lastMonth = today.subtract(1, 'month');
                    dateRange = {
                         startDate: lastMonth.startOf('month')['$d'],
                         endDate: lastMonth.endOf('month')['$d'],
                    };
                    break;
               default:
                    dateRange = calendarValue.selection;
          }

          setConfigData({ datePreset: preset });
          setCalendarValue({
               ...calendarValue,
               selection: {
                    ...dateRange,
                    key: 'selection',
               },
          });
     };

     const onApplyButtonClicked = () => {
          const { selection, compare } = calendarValue;
          const newDateRange = {
               selection: {
                    startDate: dayjs(selection.startDate),
                    endDate: selection.endDate ? dayjs(selection.endDate) : dayjs(selection.startDate),
               },
          };

          if (configData.comparePeriod && compare) {
               newDateRange.compare = {
                    startDate: dayjs(compare.startDate),
                    endDate: compare.endDate ? dayjs(compare.endDate) : dayjs(compare.startDate),
               };
          }

          if (isSelectCompare) {
               handleChangeDateRange({ ...newDateRange })
          } else {
               handleChangeDateRange({ ...configData, ...newDateRange })
          }

          setDateBoxOpened(false)
     };


     const divClasses = classNames('custom-date-wrapper save-report', { 'compare': configData.comparePeriod });

     return (
          <CCard className={divClasses} ref={customDateWrapperRef}>
               {!isSelectCompare && (
                    <div className="compare-period d-flex align-items-center justify-content-between">
                         <CFormGroup variant="custom-checkbox" inline>
                              <CInputCheckbox
                                   custom
                                   id="comparePeriod"
                                   checked={configData.comparePeriod}
                                   onChange={(e) => handleSetComparePeriod(e.target.checked)}
                              />
                              <CLabel variant="custom-checkbox" htmlFor="comparePeriod">
                                   Compare periods
                              </CLabel>
                         </CFormGroup>
                         {configData.comparePeriod && (
                              <Dropdown>
                                   <DropdownToggle className='btn-none p-0'>
                                        <span>{dropdownOpen ? 'Select Period' : configData.periodType}</span>
                                   </DropdownToggle>
                                   <DropdownMenu setDropdownOpen={setDropdownOpen}>
                                        <CIcon icon="arrow-account" className="arrow-account arrow-setting" />
                                        {Object.entries(PERIOD_TYPES).map(([key, value]) => (
                                             <div key={key} onClick={() => onChangePeriodType(value)}>
                                                  <DropdownItem>{value}</DropdownItem>
                                             </div>
                                        ))}
                                   </DropdownMenu>
                              </Dropdown>
                         )}
                    </div>
               )}

               <DateRangePicker
                    months={1}
                    maxDate={maxDate}
                    direction="vertical"
                    color="#3c4b64"
                    rangeColors={['#E4E5FF', '#FFF5D6']}
                    // className={divClasses}
                    scroll={{ enabled: true }}
                    ranges={ranges}
                    staticRanges={[]}
                    inputRanges={[]}
                    weekdayDisplayFormat="EEEEEE"
                    monthDisplayFormat="MMMM"
                    editableDateInputs={true}
                    showMonthAndYearPickers={true}
                    retainEndDateOnFirstSelection={true}
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    onChange={onChangeDateRange}
                    focusedRange={focusedRange}
                    onRangeFocusChange={onRangeFocusChange}
               />
               <div className="presets-wrapper">
                    <div className="easy-presets">
                         <p className="label">Easy presets</p>
                         <CButton
                              className={`${configData.datePreset === DATE_RANGE_PRESETS.TODAY ? 'active' : ''}`}
                              onClick={() => onClickDatePreset(DATE_RANGE_PRESETS.TODAY)}
                         >
                              Go to Today
                         </CButton>
                    </div>
                    <div className="presets">
                         {presetsToDisplay.map((presets, i) => {
                              return (
                                   <CRow key={i}>
                                        {presets.map((preset, presetIndex) => (
                                             <CCol key={presetIndex} sm={6} className="preset-col">
                                                  <CButton
                                                       className={`${preset === configData.datePreset ? 'active' : ''} btn-none`}
                                                       onClick={() => onClickDatePreset(preset)}
                                                  >
                                                       {preset}
                                                  </CButton>
                                             </CCol>
                                        ))}
                                   </CRow>
                              );
                         })}
                    </div>
               </div>
               <div className={`bottom-buttons d-flex align-items-center justify-content-end`}>
                    <CButton className='btn-none' onClick={() => setDateBoxOpened(false)}>Cancel</CButton>
                    <CButton className='btn-none' color='primary' onClick={onApplyButtonClicked}>
                         Apply
                    </CButton>
               </div>
          </CCard>
     );
};

const CustomDatePicker = (props) => {
     const { value, handleChangeDateRange, isSelectCompare } = props
     const [dateBoxOpened, setDateBoxOpened] = useState(false);
     const dateFormat = 'MMM D, YYYY';

     return (
          <div className={`custom-date-picker save-report`}>
               <CButton className="date-range-wrapper save-report btn-none" onClick={() => setDateBoxOpened(true)}>
                    <div className="date-range">
                         {value.selection && (
                              <div className="d-flex align-items-center">
                                   <span className={`selection-date-range py-1`}>
                                        {isSelectCompare ? 'Compare' : 'Selection'}: {dayjs(value.selection.startDate).format(dateFormat)} - {dayjs(value.selection.endDate).format(dateFormat)}
                                   </span>
                              </div>
                         )}
                         {value.compare && (
                              <span className='py-1'>
                                   Compare: {dayjs(value.compare.startDate).format(dateFormat)} -{' '}
                                   {dayjs(value.compare.endDate).format(dateFormat)}
                              </span>
                         )}
                    </div>
                    <CIcon icon="icon-calendar-report" className="icon-calendar-report save-report" alt="iconCalendar" />
               </CButton>
               <Popup
                    show={dateBoxOpened}
                    onClose={() => setDateBoxOpened(false)}
               >
                    <DatePickerBox handleChangeDateRange={handleChangeDateRange} value={value} isSelectCompare={isSelectCompare} setDateBoxOpened={setDateBoxOpened}/>
               </Popup>
          </div>
     );
};

export default CustomDatePicker;
