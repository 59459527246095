import { ADMIN_PATH } from '../../constants';

const adminNav = [
     {
          _tag: 'CSidebarNavItem',
          name: 'Dashboard',
          to: ADMIN_PATH.DASHBOARD,
          icon: 'cil-speedometer',
          badge: {
               color: 'info',
               // text: 'NEW',
          },
     },
     {
          _tag: 'CSidebarNavDropdown',
          name: 'Masterclass',
          route: '/master-class',
          icon: 'cil-layers',
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Courses',
                    to: '/courses',
                    exact: false,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Lessons',
                    to: '/lessons',
                    exact: false,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Tests',
                    to: '/tests',
                    exact: false,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Certificates',
                    to: '/certificates',
                    exact: false,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Coupons',
                    to: '/coupons',
                    exact: false,
               },
          ],
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'partners',
          name: 'Partners',
          to: ADMIN_PATH.PARTNERS,
          icon: 'cil-layers',
          exact: false,
          // _children: [
          //     {
          //         _tag: "CSidebarNavItem",
          //         name: "Applications",
          //         to: ADMIN_PATH.PARTNERS,
          //         exact: false
          //     },
          //     {
          //         _tag: 'CSidebarNavItem',
          //         name: 'User Partners',
          //         to: ADMIN_PATH.USER_PARTNERS
          //         exact: false
          //     },
          // ],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Office Hour',
          to: ADMIN_PATH.OFFICE_HOUR,
          icon: 'cil-soccer',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Users Course',
          to: ADMIN_PATH.USER_COURSE,
          icon: 'cil-layers',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Accounts',
          to: ADMIN_PATH.ACCOUNTS,
          icon: 'cil-browser',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'packages',
          name: 'Packages',
          to: ADMIN_PATH.PACKAGES,
          icon: 'cil-inbox',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Activities',
          to: ADMIN_PATH.ACTIVITIES,
          icon: 'cil-walk',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'users',
          name: 'Users',
          to: ADMIN_PATH.USERS,
          icon: 'cil-group',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'listeners',
          name: 'Listeners',
          to: ADMIN_PATH.LISTENERS,
          icon: 'cil-assistive-listening-system',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'features',
          name: 'Features',
          to: ADMIN_PATH.FEATURES,
          icon: 'cil-tags',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'notifications',
          name: 'Notifications',
          to: ADMIN_PATH.NOTIFICATIONS,
          icon: 'cil-tags',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'notice-action',
          name: 'Notice And Action',
          to: ADMIN_PATH.NOTICE_ACTION,
          icon: 'cil-tags',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'destinations',
          name: 'Destinations',
          to: ADMIN_PATH.DESTINATIONS,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'control',
          name: 'Control',
          to: ADMIN_PATH.CONTROL_ADMIN,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'media',
          name: 'Media',
          to: ADMIN_PATH.MEDIA,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'redis',
          name: 'Redis',
          to: ADMIN_PATH.REDIS,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'terms',
          name: 'Terms',
          to: ADMIN_PATH.TERMS,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'google-calendar',
          name: 'Google Calendar',
          to: ADMIN_PATH.GOOGLE_CALENDAR,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavDropdown',
          name: 'Users & Sessions',
          icon: 'cil-layers',
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Users',
                    to: '/visitors',
                    exact: false,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Sessions',
                    to: '/sessions',
                    exact: false,
               },
          ],
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'custom-report',
          name: 'Custom Report',
          to: ADMIN_PATH.CUSTOM_REPORT,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'Dimensions',
          name: 'Dimensions',
          to: ADMIN_PATH.DIMENSIONS,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'Metrics',
          name: 'Metrics',
          to: ADMIN_PATH.METRICS,
          icon: 'cil-control',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'Metrics',
          name: 'Contract Plans',
          to: ADMIN_PATH.CONTRACT_PLANS,
          icon: 'cil-layers',
          exact: false,
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'company-matches',
          name: 'Company Matches',
          to: ADMIN_PATH.COMPANY_MATCHES,
          icon: 'cil-layers',
          exact: false,
     },
];

export default adminNav;
