import React, { useContext, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { useSelector } from 'react-redux';
import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import { API_CLIENT_DESTINATIONS_GTM_CHANGES, GTM_WIZARD_TYPE, SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { callTokenApi } from '../../../../../../apiCaller';
import { useCancelToken } from '../../../../../../helpers/customHooks';
import { toastError } from '../../../../../../utils';
import CenterSpinner from '../../../../../general/Loadings/CenterSpinner';
import { Popup } from '../../../../../general/popup';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInputRadio } from '../../../../../migration/CInput';
import CLabel from '../../../../../migration/CLabel';

const GTMRemoveObject = () => {
     const { setCurrentStep, stepsData, setStepsData, destinationName, closeWizard } = useContext(SwitchTypeWizardContext);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const switchTypeWizard = useSelector((state) => state.subscriber.switchTypeWizard);
     const sendTo = switchTypeWizard.sendTo;

     const { currentContainer } = activeAccount.gtmInfo;
     const { verifyChanges, gtmHasUnpublishedChanges, workspaceName, gtmHasLLWorkspace } = stepsData;
     const [currentDoPublish, setCurrentDoPublish] = useState(false);
     const getCancelToken = useCancelToken();

     const [isLoading, setIsLoading] = useState(true);
     const [showLearnMorePopup, setShowLearnMorePopup] = useState(false);
     const [isPublishLearnMoreClicked, setIsPublishLearnMoreClicked] = useState(false);

     const toggleLearnMorePopup = (isPublish) => {
          setIsPublishLearnMoreClicked(isPublish);
          setShowLearnMorePopup((show) => !show);
     };

     const completeActions = () => {
          setCurrentStep(SWITCH_TYPE_WIZARD_STEP.eachMakeChanges);
     };

     const onPublishRadioChanged = (e) => {
          setStepsData({ doPublish: e.target.value === 'yes' });
          setCurrentDoPublish(e.target.value === 'yes');
     };

     const handleGeneralActions = () => {
          if (verifyChanges.length === 0) {
               let postData = {
                    accountId: activeAccount.id,
                    container: {
                         containerId: currentContainer.containerId,
                         path: currentContainer.path,
                         publicId: currentContainer.publicId,
                    },
                    name: switchTypeWizard.data.name,
                    type: switchTypeWizard.data.type,
                    action: 'Verify Changes',
                    isUpdating: switchTypeWizard.data.isUpdating,
               };

               switch (switchTypeWizard.data.type) {
                    case GTM_WIZARD_TYPE.VARIABLE:
                         postData.variableListenerId = switchTypeWizard.data.listenerId;
                         break;
                    case GTM_WIZARD_TYPE.TRIGGER:
                    case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
                    case GTM_WIZARD_TYPE.DATA_ACTION:
                         if (switchTypeWizard.data.isLookupChild) {
                              postData.ruleId = switchTypeWizard.data.ruleId;
                              postData.isLookupChild = switchTypeWizard.data.isLookupChild;
                              postData.lookupChildId = switchTypeWizard.data.id;
                         } else {
                              postData.ruleId = switchTypeWizard.data.id;
                         }

                         break;
                    case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    case GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE:
                         postData.destinationConversion = switchTypeWizard.data;
                         break;
                    default:
               }

               callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES, 'POST', postData, getCancelToken()).then((response) => {
                    if (response) {
                         if (response.status === 200) {
                              const { changes, gtmHasUnpublishedChanges, workspaceName, workspacePath, gtmHasLLWorkspace } = response.data;
                              const newStepsData = {
                                   verifyChanges: changes,
                                   gtmHasUnpublishedChanges,
                                   workspaceName,
                                   workspacePath,
                                   gtmHasLLWorkspace,
                              };
                              setStepsData(newStepsData);
                         } else {
                              toastError(response);

                              if (response.data.noWritePermission) {
                                   closeWizard();
                              }
                         }
                         setIsLoading(false);
                    }
               });
          } else {
               setIsLoading(false);
          }
     };

     const handleFirstLoad = () => {
          handleGeneralActions();
     };

     useEffect(handleFirstLoad, []); // eslint-disable-line

     const goToNextStep = async () => {
          setStepsData({ skipRemove: true });
          if (sendTo === 'either' && !stepsData.skipCreate) {
               setCurrentStep(SWITCH_TYPE_WIZARD_STEP.eachMakeChanges);
          } else {
               setCurrentStep(SWITCH_TYPE_WIZARD_STEP.gtmSuccess);
               if (typeof switchTypeWizard.data.doNextSwitch === 'function') {
                    switchTypeWizard.data.doNextSwitch();
               }
          }
     };

     const handleSaveNoChange = () => {
          goToNextStep();
     };

     return (
          <SwitchTypeWizardBody shouldHideBackBtn={true}>
               <CCard className="account-script verify-changes">
                    <CCardBody>
                         {isLoading ? (
                              <CenterSpinner />
                         ) : verifyChanges.filter((item) => item.includes('Delete the tag')).length > 0 ? (
                              <>
                                   <h3>Processing the Current Tags</h3>
                                   <p className="des mb-4">Here is a list of the specific objects we'll remove from GTM.</p>
                                   {/* <p className="des mb-3">
                                        You can choose whether to publish the changes. We'll create a new version; you can always revert inside GTM.
                                   </p> */}
                                   <div className="list-action">
                                        {verifyChanges
                                             .filter((item) => item.includes('Delete the tag'))
                                             .map((change, i) => {
                                                  const sanitizedChange = DOMPurify.sanitize(change);
                                                  return <div key={i} className="action" dangerouslySetInnerHTML={{ __html: sanitizedChange }}></div>;
                                             })}
                                   </div>
                                   <div className="radio-group">
                                        <CFormGroup variant="custom-radio">
                                             <CInputRadio id="publish-radio1" name="publishRadios" value="yes" onChange={onPublishRadioChanged} />
                                             <CLabel htmlFor="publish-radio1">Publish the changes in GTM.</CLabel>
                                             <CButton type="button" className="btn-learn-more" onClick={() => toggleLearnMorePopup(true)}>
                                                  Learn more<i className="fal fa-angle-right"></i>
                                             </CButton>
                                        </CFormGroup>
                                   </div>
                                   <CButton
                                        type="button"
                                        className="btn-save"
                                        onClick={completeActions}
                                        disabled={!currentDoPublish && verifyChanges.filter((item) => item.includes('Delete the tag')).length > 0}
                                   >
                                        COMPLETE THESE ACTIONS
                                   </CButton>
                              </>
                         ) : (
                              <>
                                   <h3>We Found Nothing to Remove</h3>
                                   <p className="des mb-0">
                                        {`We haven't found any ${destinationName} objects we created inside GTM that need to be removed.`}
                                        However, we recommend that you manually review your website and GTM to ensure now client-side{' '}
                                        {destinationName} scripts are loading.
                                   </p>
                                   <CButton type="button" className="btn-save" onClick={handleSaveNoChange}>
                                        Next
                                   </CButton>
                              </>
                         )}
                    </CCardBody>
               </CCard>
               <Popup className="learn-more account-script-popup" showHeader={true} show={showLearnMorePopup} onClose={toggleLearnMorePopup}>
                    {gtmHasUnpublishedChanges ? (
                         isPublishLearnMoreClicked ? (
                              <>
                                   <p>
                                        We'll publish our changes to ensure everything is live on your website. This will create a new version in GTM
                                        which will allow you to easily revert if needed.
                                   </p>
                                   {gtmHasLLWorkspace ? (
                                        <p>
                                             We found an unpublished workspace called <strong>{workspaceName}</strong> that you've been using to push
                                             changes into. We'll make these changes to that workspace and publish the entire workspace.
                                        </p>
                                   ) : (
                                        <p>
                                             We did find that your container already has some unpublished changes, and we don't want to publish those.
                                             As a result, we'll create a unique workspace so that only our changes will be published. You can then
                                             merge these changes into your other workspace at some point in the future.
                                        </p>
                                   )}
                              </>
                         ) : (
                              <>
                                   <p>
                                        We'll create the elements inside your GTM account, and you'll be required to publish them manually. We
                                        recommend publishing soon to ensure that ListenLayer and GTM remain in sync.
                                   </p>
                                   {gtmHasLLWorkspace ? (
                                        <p>
                                             We found an unpublished workspace called <strong>{workspaceName}</strong> that you've been using to push
                                             changes into. We'll make these changes to that workspace but it won't be published - you'll need to
                                             review the changes inside GTM and publish the workspace there.
                                        </p>
                                   ) : (
                                        <p>
                                             We did find that your container already has some unpublished changes, and we don't want to publish those.
                                             As a result, we'll create a unique workspace so that only these changes can be isolated. Review the new
                                             workspace and publish it when you are ready.
                                        </p>
                                   )}
                              </>
                         )
                    ) : isPublishLearnMoreClicked ? (
                         <>
                              <p>
                                   We'll publish our changes to ensure everything is live on your website. This will create a new version in GTM which
                                   will allow you to easily revert if needed.
                              </p>
                              <p>
                                   We've checked your GTM account and found that there are no other unpublished changes in the container, which means
                                   only our changes are going live.
                              </p>
                         </>
                    ) : (
                         <>
                              <p>
                                   We'll create the elements inside your GTM account, and you'll be required to publish them manually. We recommend
                                   publishing soon to ensure that ListenLayer and GTM remain in sync.
                              </p>
                              <p>
                                   We've checked your GTM account and found that there are no other unpublished changes in the container, which means
                                   only our changes are going live.
                              </p>
                         </>
                    )}
               </Popup>
          </SwitchTypeWizardBody>
     );
};

export default GTMRemoveObject;
