import { CButton, CCard, CCardBody } from '@coreui/react';
import DOMPurify from 'dompurify';
import React, { useContext } from 'react';

import { SwitchTypeWizardBody, SwitchTypeWizardContext } from '../SwitchTypeWizard';
import { SWITCH_TYPE_WIZARD_STEP } from '../../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { setAccountDestination } from '../../../../../../actions/subscriber';

const GTMUnknownFailure = () => {
     const dispatch = useDispatch();
     const { stepsData, closeWizard, handleUpdateSwitchType, initUsingType } = useContext(SwitchTypeWizardContext);
     const accountDestination = useSelector((state) => state.subscriber.accountDestination);
     const handleGoback = () => {
          dispatch(setAccountDestination({ ...accountDestination, initStep: initUsingType }));
          handleUpdateSwitchType({ initStep: SWITCH_TYPE_WIZARD_STEP.getStarted });
          closeWizard();
     };
     const defaultMessages = {
          headline: 'An error occurred!',
          description: `We're having issues making these changes to your GTM account and the system isn't providing a specific reason.`,
     };
     let messages = defaultMessages;

     if (stepsData.isRunOutOfWorkspace) {
          messages = {
               headline: 'Your GTM account has no available workspaces.',
               description: `Publish any changes and open up your workspaces before we can make changes in your GTM account.`,
          };
     }

     if (stepsData.publishFailed) {
          messages = {
               headline: 'An error occurred!',
               description: `There was an error publishing your GTM changes but the system did not provide details about the cause of the error. We recommend logging into your GTM and clearing the error.`,
          };
     }

     if (stepsData.duplicateTriggerName) {
          messages = {
               headline: 'An error occurred!',
               description: `There was an error editing your GTM due to create duplicate trigger. Trigger name: <strong>${stepsData.duplicateTriggerName}</strong>.`,
          };
     }
     if (stepsData.duplicateTagName) {
          messages = {
               headline: 'An error occurred!',
               description: `There was an error editing your GTM due to create duplicate tag. Tag name: <strong>${stepsData.duplicateTagName}</strong>.`,
          };
     }

     const sanitizedMessagesDescription = DOMPurify.sanitize(messages?.description || '');

     return (
          <SwitchTypeWizardBody>
               <CCard className="account-script unknown-failure">
                    <CCardBody>
                         <h3>{messages.headline}</h3>
                         <p dangerouslySetInnerHTML={{ __html: sanitizedMessagesDescription }}></p>
                         <CButton className="btn-save" onClick={handleGoback}>
                              Go Back
                         </CButton>
                    </CCardBody>
               </CCard>
          </SwitchTypeWizardBody>
     );
};

export default GTMUnknownFailure;
