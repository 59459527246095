import { CButton } from '@coreui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { callTokenApi } from '../../../../apiCaller';
import {
     API_CLIENT_EXPORT_REPORTING_EXPLORER,
     CHECK_LIST_OPERATOR_OPTION,
     DEFAULT_DATE_FORMAT,
     EXPORT_TYPE,
     PLATFORM_ACCOUNT_STATUSES,
     REPORT_DATA_TYPES,
     REPORT_NAMES,
} from '../../../../constants';
import { exportToCSV2, exportVisibleReport } from '../../../../utils';
import { getCustomPropertiesReport, handleChangeColSort, handleChangeColSortDimension } from '../../../../utils/UtilReport';
import { EventExplorerContext } from './EventExplorer';

const ExportReport = () => {
     const {
          filterMetric,
          filterDimensions,
          filterProperties,
          reportName,
          sort,
          filterEvent,
          itemsPerPage,
          offSet,
          scoped,
          reportType,
          conversionNameMetric,
          conversionName,
          showData,
          dateRangeConfig,
          customProperties,
          newProperties: propertiesAll,
          recordProfile,
          isLoading,
          // metricDefault,
          // convRate,
          // convName,
          // eCommerce,
          maxStepForm,
          eCoItemCategories,
          weight,
          attributeBeforeReload,
          metricsStepForm,
          reportData,
          tempReport,
          reportCompare,
          // tableData,
          listSegmentReportApply,
          utmArray,
          isWebConversion,
          dimensionEvents,
     } = useContext(EventExplorerContext);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     // const customDimensions = useSelector((state) => state.subscriber.customReport.dimensions);
     let { dimensions, metrics } = reportType ? REPORT_DATA_TYPES[reportName][scoped] : REPORT_DATA_TYPES[reportName];
     if (reportName === REPORT_NAMES.CONVERSION_SOURCE_ATTRIBUTION_REPORT) {
          dimensions = [...dimensions, ...utmArray];
     }
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const user = useSelector((state) => state.theme.user);
     const [disableExport, setDisableExport] = useState(false);
     const segments = listSegmentReportApply;
     // let newFilterMetric = [];
     // if (segments && segments.length > 0) {
     //      filterMetric.forEach((item) => {
     //           const findItem = [...conversionNameMetric, ...metrics, ...metricsStepForm].find((el) => item.value.includes(el.key));
     //
     //           if (findItem) {
     //                newFilterMetric.push({ value: findItem.key, label: item.label });
     //           }
     //      });
     // } else {
     //      newFilterMetric = [...filterMetric];
     // }

     if (reportName === REPORT_NAMES.ECOMMERCE_ITEMS) {
          dimensions = [...dimensions, ...eCoItemCategories];
     }

     useEffect(() => {
          if (activeAccount.platformAccountStatus !== PLATFORM_ACCOUNT_STATUSES.SANDBOX || localStorage.getItem('llByPassSandbox') || window.location.search.includes('bypass-sandbox=1')) {
               setDisableExport(isLoading);
          } else {
               setDisableExport(true);
          }
     }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
     const handleOrderCol = (arr, arrFilter) => {
          const newArr = [];
          arr.forEach((item) => {
               if (arrFilter.some((el) => el.value === item.key)) {
                    newArr.push(item.key);
               }
          });
          return newArr;
     };

     // const handleOrderColMetric = (arr, arrFilter) => {
     //      const newArr = [];
     //      arr.forEach((item) => {
     //           if (arrFilter.some((el) => el.value === item.key)) {
     //                newArr.push(item.key);
     //           }
     //      });
     //      return newArr;
     // };

     // const handleColMetricStepForm = (arrDefault) => {
     //      const newArr = [];
     //      arrDefault.forEach((item) => {
     //           if (item.value.includes('step')) {
     //                newArr.push(item.value);
     //           }
     //      });
     //      return newArr;
     // };

     // const handleOrderColV2 = (arr, arrFilter) => {
     //      const newArr = [];
     //      arr.forEach((item) => {
     //           if (arrFilter.some((el) => el.value === item.key)) {
     //                newArr.push(item.key);
     //           }
     //      });
     //      return newArr;
     // };
     const handleExportCSV = (typeExport) => {
          if (!showData) {
               return;
          }

          const newDateRange = {
               selection: {
                    startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
               },
          };

          if (dateRangeReports.compare) {
               newDateRange.compare = {
                    startDate: dateRangeReports.compare.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.compare.endDate.format(DEFAULT_DATE_FORMAT),
               };
          }
          if (dateRangeConfig.dateScope) {
               newDateRange.dateScope = dateRangeConfig.dateScope;
          }
          // const newDimensions = handleOrderCol(dimensions, filterDimensions);
          // const newMetric = handleChangeColSort([...conversionNameMetric, ...metrics], filterMetric).map((i) => i.key);
          // const newProperties = handleOrderCol(propertiesAll, filterProperties);
          const newProperties = attributeBeforeReload.properties;
          const newMetric = attributeBeforeReload.metrics;
          // const newMetricsStepForm = handleColMetricStepForm(metricDefault);
          // const headerColumn = [...filterDimensions, ...filterProperties, ...metricDefault, ...convRate, ...convName, ...eCommerce];
          const headerColumn = [...filterDimensions, ...filterProperties, ...filterMetric];
          const newFilter = filterEvent.map((item) => {
               const newItem = { ...item };

               if (item.operator === CHECK_LIST_OPERATOR_OPTION.value) {
                    newItem.value = item.value && Array.isArray(item.value) ? [...item.value].map((el) => el.value) : [];
               }
               delete newItem['optionsOperator'];
               return newItem;
          });
          const filterFields = {
               metrics: handleChangeColSort([...metrics, ...conversionNameMetric, ...metricsStepForm], filterMetric).map((i) => i.key),
               properties: handleOrderCol(propertiesAll, filterProperties),
               // dimensions: handleOrderCol(dimensions, filterDimensions),
               dimensions: handleOrderCol(dimensions, filterDimensions),
          };
          // const handleCheckSegmentNoData  = ()=>{
          //      const segmentFilter = [];
          //      segments.forEach((segment, ind) => {
          //           //#region Check report segment if the data is all 0 values, then hide it on the table report
          //           const newArraySegment = [];
          //           const segmentName = camelCase(segment.name);
          //           tableData.forEach((data)=> {
          //                for (const key in data) {
          //                     if (data.hasOwnProperty(key) && key.includes(segmentName)) {
          //                          newArraySegment.push({ [key]: data[key]});
          //                     }
          //                }                                                   
          //           })
          //           const arrayNoData = newArraySegment.length > 0 && newArraySegment.every(obj => Object.values(obj)[0] === 0 ||  Object.values(obj)[0] === "0" || Object.values(obj)[0] === 'None');
          //           if(!arrayNoData){
          //                segmentFilter.push(segment)
          //           }
          //      })
          //      return segmentFilter
          // }
          let bodyData = {
               reportName,
               accountId: activeAccount.id,
               dateRanges: newDateRange,
               metrics: handleChangeColSort([...conversionNameMetric, ...metrics, ...metricsStepForm], filterMetric).map((i) => i.key),
               properties: handleOrderCol(propertiesAll, filterProperties),
               dimensions: handleChangeColSortDimension([...dimensions], filterDimensions).map((i) => i.key),
               filter: newFilter,
               sort: sort,
               headerColumn: headerColumn,
               user: user,
               pathName: window.location.pathname,
               scoped: scoped,
               reportType: reportType,
               // conversionName: conversionName.filter((item) => item.label.includes('Conv')),
               // eCommerceName: conversionName.filter((item) => item.label.includes('Ecommerce')),
               conversionName: conversionName.filter(
                    (conversion) => newMetric.find((metric) => metric.includes(conversion.value)) && conversion.label.includes('Conv')
               ),
               eCommerceName: conversionName.filter((conversion) => newMetric.includes(conversion.value) && conversion.label.includes('Ecommerce')),
               customProperties: getCustomPropertiesReport(customProperties, newProperties),
               isCompare: dateRangeConfig.comparePeriod,
               recordProfile,
               limit: itemsPerPage,
               offset: offSet,
               weight,
               segments,
               temp: tempReport.temp,
               tempCompare: tempReport.tempCompare,
          };
          if (newDateRange.compare && (!reportType || reportName === REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION)) {
               delete newDateRange.compare;
               bodyData.isCompare = false;
          }
          if (reportName === 'formCategoryReport') {
               bodyData.maxStepForm = maxStepForm;
          }

          if (reportName === 'conversionEvents') {
               bodyData.isWebConversion = isWebConversion;
               bodyData.dimensionEvent = dimensionEvents;
          }
          
          setDisableExport(true);

          if (typeExport === EXPORT_TYPE.EXPORT_ALL_PAGE) {
               callTokenApi(API_CLIENT_EXPORT_REPORTING_EXPLORER, 'POST', bodyData);
               setTimeout(() => {
                    toast.success('Your file has been emailed, it may take a few minutes to send, please check your email');
                    setDisableExport(false);
               }, 3000);
          } else {
               const tempFileName = `${reportName}.csv`;
               const csvString = exportVisibleReport({
                    data: reportData,
                    filterFields,
                    newProperties,
                    newMetric,
                    headerColumn,
                    reportName,
                    reportCompare,
                    isCompare: bodyData.isCompare,
                    reportType,
                    segments,
                    newDateRange,
               });
               if (csvString) {
                    exportToCSV2(csvString, tempFileName);
               }
               setDisableExport(false);
          }
     };

     return (
          <div className="export-report filter-report d-flex justify-content-end align-items-center">
               <CButton className="filter-button mr-2" disabled={disableExport} onClick={() => handleExportCSV(EXPORT_TYPE.EXPORT_ALL_PAGE)}>
                    {' '}
                    Export the entire data set{' '}
               </CButton>
               <CButton className="filter-button" disabled={disableExport} onClick={() => handleExportCSV(EXPORT_TYPE.EXPORT_A_PAGE)}>
                    {' '}
                    Export Visible Page{' '}
               </CButton>
          </div>
     );
};

export default ExportReport;
