import { SUBSCRIBER_PATH, SUBSCRIBER_MY_ACCOUNT_PATH } from '../../constants';

const defaultSubscriberNav = [
     {
          _tag: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/',
          icon: 'cil-speedometer',
          badge: {
               color: 'info',
               // text: 'NEW',
          },
     },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['INTEGRATIONS'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Website',
          to: SUBSCRIBER_PATH.WEBSITE,
          icon: 'cil-code',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Listeners',
          to: SUBSCRIBER_PATH.LISTENERS,
          icon: 'cil-layers',
     },
     // {
     //      _tag: 'CSidebarNavItem',
     //      name: 'Tag Managers',
     //      to: SUBSCRIBER_PATH.TAG_MANAGERS,
     //      icon: 'cil-tags'
     // },
     // {
     //      _tag: 'CSidebarNavItem',
     //      name: 'Browser Add-ons',
     //      to: SUBSCRIBER_PATH.BROWSER_ADDONS,
     //      icon: 'cil-browser'
     // },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['GLOBAL SETTINGS'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Custom Data Layer Variables',
          to: SUBSCRIBER_PATH.DATA_LAYER_VARIABLES,
          icon: 'cil-puzzle',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Browser Storage Variables',
          to: SUBSCRIBER_PATH.BROWSER_STORAGE_VARIABLES,
          icon: 'cil-puzzle',
     },
];

const subscriberMyAccountNav = [
     {
          _tag: 'CSidebarNavTitle',
          _children: ['ACCOUNTS'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'My Accounts',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.MY_ACCOUNTS,
          icon: 'cil-user',
     },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['LEARNING'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Masterclass',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.MASTERCLASS,
          icon: 'cil-user',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Certification',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.CERTIFICATION,
          icon: 'cil-bell',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Office Hours',
          className: 'new',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.OFFICE_HOURS,
          icon: 'cil-dollar',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Become a Partner',
          className: 'new',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.BECOME_A_PARTNER,
          icon: 'cil-account-logout',
     },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['SUPPORT'],
     },
     {
          _tag: 'CSidebarNavItem',
          id: 'support-tickets-nav',
          name: 'My Tickets',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.SUPPORT_TICKETS,
          icon: 'icon-lifeRingSidebar',
     },
     {
          _tag: 'CSidebarNavTitle',
          _children: ['MY DETAILS'],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'My Profile',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.PROFILE,
          icon: 'cil-user',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Email Notifications',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.EMAIL_NOTIFICATIONS,
          icon: 'cil-bell',
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Payment Methods',
          to: SUBSCRIBER_MY_ACCOUNT_PATH.PAYMENT_METHODS,
          icon: 'cil-dollar',
     },
];

const subscriberNavKey = {
     INSIGHTS: 'Insights',
     GOALS: 'Goals',
     LISTENERS: 'Sources',
     DESTINATIONS: 'Destinations',
     MONITORING: 'Health',
     CONSENT_PRIVACY: 'Consent',
     DASHBOARD: 'Dashboard',
};

const defaultSubscriberMonitoringNav = [
     {
          _tag: 'CSidebarNavItem',
          name: 'Dashboard',
          to: SUBSCRIBER_PATH.MONITORING_DASHBOARD,
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Monitoring Notifications',
          to: SUBSCRIBER_PATH.MONITORING_NOTIFICATIONS_V2,
          icon: 'iconBell',
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Automatic Monitoring',
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Conversions',
                    icon: 'cil-layers',
                    to: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_CONVERSIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Listeners',
                    icon: 'icon-hubspotTinted',
                    to: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_LISTENERS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Destinations',
                    icon: 'icon-driftTinted',
                    to: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_DESTINATIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Form Categories',
                    icon: 'icon-driftTinted',
                    to: SUBSCRIBER_PATH.AUTOMATIC_MONITORING_FORMCATEGORIES,
               },
          ],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Custom Monitoring',
          to: SUBSCRIBER_PATH.CUSTOM_MONITORING_RULES,
          exact: false,
     },
];

let reportNav = []; //eslint-disable-line

if (localStorage.getItem('llShowData') || window.location.search.includes('show-data=1')) {
     // eslint-disable-next-line no-unused-vars
     reportNav = [
          {
               _tag: 'CSidebarNavItem',
               name: 'User Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_USER_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Event Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_EVENT_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Page View Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_PAGE_VIEW_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Session Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_SESSION_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Person Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_PERSON_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Conversion Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Conversion Name Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_NAME_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'User Source Session Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_USER_SOURCE_SESSION_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'User Source User Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_USER_SOURCE_USER_EXPLORER,
          },
          {
               _tag: 'CSidebarNavItem',
               name: 'Entrance And Exit Explorer',
               to: SUBSCRIBER_PATH.INSIGHTS_ENTRANCE_AND_EXIT_EXPLORER,
          },
     ]; //eslint-disable-line
}

const defaultSubscriberAnalyticsNav = [
     {
          _tag: 'CSidebarNavItem',
          name: 'Overview',
          // to: SUBSCRIBER_PATH.INSIGHTS_DATA_LAYER,
          to: '/:secondId',
          badge: {
               color: 'info',
          },
     },
     // ...reportNav,

     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Attributes',
          to: SUBSCRIBER_PATH.INSIGHTS_ATTRIBUTES,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Consent',
                    to: SUBSCRIBER_PATH.INSIGHTS_CONSENT,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Geography',
                    to: SUBSCRIBER_PATH.INSIGHTS_GEOGRAPHY,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Devices',
                    to: SUBSCRIBER_PATH.INSIGHTS_DEVICES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Browsers',
                    to: SUBSCRIBER_PATH.INSIGHTS_BROWSERS,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Acquisition',
          to: SUBSCRIBER_PATH.INSIGHTS_ACQUISITION,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Channels',
                    to: SUBSCRIBER_PATH.INSIGHTS_CHANNEL,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Sources',
                    to: SUBSCRIBER_PATH.INSIGHTS_SOURCE,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'UTMs',
                    to: SUBSCRIBER_PATH.INSIGHTS_UTMS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Referrals',
                    to: SUBSCRIBER_PATH.INSIGHTS_REFERRALS,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Engagement',
          to: SUBSCRIBER_PATH.INSIGHTS_ENGAGEMENT,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'New vs Returning',
                    to: SUBSCRIBER_PATH.INSIGHTS_NEW_RETURNING,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Session Count',
                    to: SUBSCRIBER_PATH.INSIGHTS_SESSION_COUNT,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Session Duration',
                    to: SUBSCRIBER_PATH.INSIGHTS_SESSION_DURATION,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Conversion Count',
                    to: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_COUNT,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'People and User Count',
                    to: SUBSCRIBER_PATH.INSIGHTS_PEOPLE_COUNT,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Content',
          to: SUBSCRIBER_PATH.INSIGHTS_CONTENT,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'All Pages',
                    to: SUBSCRIBER_PATH.INSIGHTS_ALL_PAGES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Landing Pages',
                    to: SUBSCRIBER_PATH.INSIGHTS_LANDING_PAGES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Exit Pages',
                    to: SUBSCRIBER_PATH.INSIGHTS_EXIT_PAGES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Entrance And Exit Pages',
                    to: SUBSCRIBER_PATH.INSIGHTS_ENTRANCE_EXIT_PAGES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Scroll Depth',
                    to: SUBSCRIBER_PATH.INSIGHTS_SCROLL_DEPTH,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Visible Time',
                    to: SUBSCRIBER_PATH.INSIGHTS_VISIBLE_TIME,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Page Flow',
                    to: SUBSCRIBER_PATH.INSIGHTS_PAGE_FLOW,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Conversions',
          to: SUBSCRIBER_PATH.INSIGHTS_CONVERSIONS,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'All Conversions',
                    to: SUBSCRIBER_PATH.INSIGHTS_ALL_CONVERSIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'By URL',
                    to: SUBSCRIBER_PATH.INSIGHTS_BY_URL,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'By Source',
                    to: SUBSCRIBER_PATH.INSIGHTS_BY_SOURCE,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'By UTM',
                    to: SUBSCRIBER_PATH.INSIGHTS_BY_UTM,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'By Landing Page',
                    to: SUBSCRIBER_PATH.INSIGHTS_BY_LANDING_PAGE,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'By Page Journey',
                    to: SUBSCRIBER_PATH.INSIGHTS_BY_PAGE_JOURNEY,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Attribution',
                    to: SUBSCRIBER_PATH.INSIGHTS_ATTRIBUTION,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'eCommerce',
          to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Purchase Flow',
                    to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_FLOW,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Items',
                    to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_ITEMS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Purchase Activity',
                    to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_ACTIVITY,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Purchase Transaction',
                    to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PURCHASE_TRANSACTIONS,
               },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Overview',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_OVERVIEW,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Transactions',
               //      to: SUBSCRIBER_PATH.INSIGHTS_TRANSACTIONS,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'By Source',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_BY_SOURCE,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'By UTM',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_BY_UTM,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Product Performance',
               //      to: SUBSCRIBER_PATH.INSIGHTS_ECOMMERCE_PRODUCT_PERFORMANCE,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Purchase Funnel',
               //      to: SUBSCRIBER_PATH.INSIGHTS_PURCHASE_FUNNEL,
               // },
          ],
     },
     // {
     //      _tag: 'CSidebarNavDropdown',
     //      name: 'Funnels & Paths',
     //      to: SUBSCRIBER_PATH.INSIGHTS_FUNNELS_PATHS,
     // },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Explore Data',
          to: SUBSCRIBER_PATH.INSIGHTS_EXPLORE_DATA,
          showTooltip: false,
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Events',
                    to: SUBSCRIBER_PATH.INSIGHTS_EVENTS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Conversion Events',
                    to: SUBSCRIBER_PATH.INSIGHTS_CONVERSION_EVENTS,
               },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Pages',
               //      to: SUBSCRIBER_PATH.INSIGHTS_PAGES,
               // },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Conversions',
               //      to: SUBSCRIBER_PATH.INSIGHTS_EXPLORE_CONVERSIONS,
               // },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Sessions',
                    to: SUBSCRIBER_PATH.INSIGHTS_SESSIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Session Replays',
                    to: SUBSCRIBER_PATH.INSIGHTS_SESSION_REPLAYS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Users',
                    to: SUBSCRIBER_PATH.INSIGHTS_USERS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'People',
                    to: SUBSCRIBER_PATH.INSIGHTS_PEOPLE,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Revealed Companies',
                    to: SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Page View Explorer',
                    to: SUBSCRIBER_PATH.INSIGHTS_PAGE_VIEW_EXPLORER,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Form Explorer',
                    to: SUBSCRIBER_PATH.INSIGHTS_FORM_CATEGORY,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Form Submission Event',
                    to: SUBSCRIBER_PATH.INSIGHTS_FORM_SUBMISSION_EVENTS,
               },
          ],
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Custom Reports',
          to: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_REPORTS,
     },
     {
          _tag: 'CSidebarNavDropdownCustom',
          name: 'Data Settings',
          icon: 'cil-settings',
          className: 'item-dropdown-toggle sidebar-nav-dropdown-last',
          _children: [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Metrics & Dimensions',
                    to: SUBSCRIBER_PATH.INSIGHTS_METRICS_AND_DIMENSIONS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Channels',
                    to: SUBSCRIBER_PATH.INSIGHTS_CHANNELS,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Segments',
                    to: SUBSCRIBER_PATH.SEGMENT,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Custom Data Sets',
                    to: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_DATA_SETS,
                    exact: false,
               },
              
          ],
     },
     {
          _tag: 'CSidebarNavItem',
          name: 'Save Reports',
          to: SUBSCRIBER_PATH.SAVE_REPORTS,
     },
];

if (localStorage.getItem('llShowTool') || window.location.search.includes('show-tool=1')) {
     if (!localStorage.getItem('llShowTool')) {
          localStorage.setItem('llShowTool', '1');
     }

     defaultSubscriberAnalyticsNav.push({
          _tag: 'CSidebarNavItem',
          name: 'Report Tool',
          to: SUBSCRIBER_PATH.INSIGHTS_REPORT_TOOL,
          exact: true,
     });
}

export {
     defaultSubscriberNav,
     subscriberMyAccountNav,
     subscriberNavKey,
     defaultSubscriberMonitoringNav,
     defaultSubscriberAnalyticsNav,
};
