import React, { useState } from 'react';
import Select from 'react-select';
import CFormGroup from '../../../../../migration/CFormGroup';
import { CInput, CInputCheckbox } from '../../../../../migration/CInput';
import CLabel from '../../../../../migration/CLabel';
import { PERIOD_TYPES, PERIOD_TYPES_NEW } from '../../../../../../constants';
import CustomDatePicker from './SelectDateRange';
import { CInvalidFeedback } from '../../../../../migration/CInvalidFeedback';

const RollingDateRange = (props) => {
     const { values, setFieldValue, setFieldError, errors, defaultDateRange } = props
     const rollingDateRangeOptions = [
          { value: 'previous_week_sun_sat', label: 'Previous Week (Sun - Sat)' },
          { value: 'previous_week_mon_sun', label: 'Previous Week (Mon - Sun)' },
          { value: 'previous_month', label: 'Previous Month' },
          { value: 'month_to_date_yesterday', label: 'Month to Date (yesterday)' },
          { value: 'year_to_date_yesterday', label: 'Year to Date (yesterday)' },
          { value: 'quarter_to_date', label: 'Quarter to Date (yesterday)' },
          { value: 'previous_x_days', label: 'Previous X Days' },
          { value: 'previous_x_weeks', label: 'Previous X Weeks' }
     ];

     const isPrevious = ['previous_x_days', 'previous_x_weeks'].includes(values.dateRangeConfig.rolling?.type);

     const periodTypeDefaultOption = Object.values(PERIOD_TYPES).map((item) => {
          return { value: item, label: item }
     })

     const [periodTypeOption, setPeriodTypeOption] = useState(() => {
          if (values?.dateRangeConfig?.rolling?.periodType) {
               if (periodTypeDefaultOption.find((item) => item.value === values?.dateRangeConfig?.rolling?.periodType)) {
                    return periodTypeDefaultOption
               } else {
                    return [...periodTypeDefaultOption, { value: values?.dateRangeConfig?.rolling?.periodType, label: values?.dateRangeConfig?.rolling?.periodType }]
               }
          } else {
               return periodTypeDefaultOption
          }
     })

     const handleChangeDateRangeCompare = (data) => {
          setFieldValue('dateRangeConfig.rolling.customDateCompare', data)
     }

     return (
          <>
               <CFormGroup>
                    <Select
                         className='width_200'
                         classNamePrefix='react-select'
                         options={rollingDateRangeOptions}
                         value={rollingDateRangeOptions.filter((option) => option.value === values.dateRangeConfig.rolling?.type)}
                         onChange={(option) => {
                              setFieldValue('dateRangeConfig.rolling.type', option.value)
                              setFieldValue('dateRangeConfig.rolling.numberPrevious', null)

                              let newPeriodTypeOption = periodTypeDefaultOption

                              if (option.value === 'month_to_date_yesterday') {
                                   newPeriodTypeOption = [...periodTypeDefaultOption, { value: PERIOD_TYPES_NEW.PREVIOUS_MONTH, label: PERIOD_TYPES_NEW.PREVIOUS_MONTH }]
                              }

                              if (option.value === 'quarter_to_date') {
                                   newPeriodTypeOption = [...periodTypeDefaultOption, { value: PERIOD_TYPES_NEW.PREVIOUS_QUARTER, label: PERIOD_TYPES_NEW.PREVIOUS_QUARTER }]
                              }

                              if (values?.dateRangeConfig?.rolling?.isComparison) {
                                   if (!newPeriodTypeOption.find((item) => item.value === values?.dateRangeConfig?.rolling?.periodType)) {
                                        setFieldValue('dateRangeConfig.rolling.periodType', 'Previous Period')
                                   }
                              }

                              setPeriodTypeOption(newPeriodTypeOption)
                         }}
                    />
               </CFormGroup>

               {
                    isPrevious && (
                         <CFormGroup>
                              <div className={`d-flex align-items-center mt-3 ${errors?.dateRangeConfig?.rolling?.numberPrevious ? 'is-invalid' : ''}`}>
                                   <CInput
                                        id="previous"
                                        invalid={errors?.dateRangeConfig?.rolling?.numberPrevious}
                                        required
                                        value={values.dateRangeConfig.rolling?.numberPrevious}
                                        onChange={(e) => {
                                             setFieldValue('dateRangeConfig.rolling.numberPrevious', e.target.value)
                                             setFieldError('dateRangeConfig.rolling.numberPrevious', undefined)
                                        }}
                                        onKeyDown={(e) => {
                                             if (e.key === '.' || e.key === ',' || e.key === '-') {
                                                  e.preventDefault();
                                             }
                                        }}
                                        min={1}
                                        type='number'
                                        className='width_100 mr-2 pr-2'
                                        style={{ height: 'auto' }}
                                   />
                                   <CLabel className="mr-5 d-block">
                                        {values.dateRangeConfig.rolling?.type === 'previous_x_days' ? 'Days' : 'Weeks'}
                                   </CLabel>
                              </div>
                              <CInvalidFeedback>{errors?.dateRangeConfig?.rolling?.numberPrevious}</CInvalidFeedback>
                         </CFormGroup>
                    )
               }
               <div className='my-3'>
                    <CFormGroup variant="custom-checkbox" inline>
                         <CInputCheckbox
                              custom
                              id="isComparison"
                              checked={values.dateRangeConfig.rolling?.isComparison}
                              onChange={(e) => {
                                   setFieldValue('dateRangeConfig.rolling.isComparison', e.target.checked)
                                   setFieldValue('dateRangeConfig.rolling.customDateCompare', null)
                                   if (e.target.checked) {
                                        setFieldValue('dateRangeConfig.rolling.periodType', 'Previous Period')
                                   }
                              }}
                         />
                         <CLabel variant="custom-checkbox" htmlFor="isComparison">
                              Include a date comparison
                         </CLabel>
                    </CFormGroup>
               </div>


               {values.dateRangeConfig.rolling?.isComparison && (
                    <>
                         <CFormGroup>
                              <Select
                                   className='width_200'
                                   classNamePrefix='react-select'
                                   options={periodTypeOption}
                                   value={periodTypeOption.filter((option) => option.value === values.dateRangeConfig.rolling?.periodType)}
                                   onChange={(option) => {
                                        setFieldValue('dateRangeConfig.rolling.periodType', option.value)
                                        setFieldValue('dateRangeConfig.rolling.customDateCompare', option.value === 'Custom' ? defaultDateRange : null)
                                   }}
                              />
                         </CFormGroup>


                         {values.dateRangeConfig.rolling?.periodType === 'Custom' && (
                              <CustomDatePicker handleChangeDateRange={handleChangeDateRangeCompare} value={values.dateRangeConfig.rolling?.customDateCompare} isSelectCompare={true} />
                         )}

                    </>
               )}


          </>
     )
}

export default RollingDateRange;
