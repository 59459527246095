import { CButton, CModal, CModalBody } from '@coreui/react';
import DOMPurify from 'dompurify';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
     setDataSetupGuideDataCompleted,
     setSetupGuideRoadmap,
     setShowRoadMap,
     setShowSetupGuidePopup,
     setUserTerms,
} from '../../../actions/subscriber';
import { callTokenApi } from '../../../apiCaller';
import { API_CLIENT_ACCOUNT_SETUP_GUIDE_DATA, API_CLIENT_ADD_USER_TERM, SCHEDULE_DEMO, SUBSCRIBER_MY_ACCOUNT_PATH } from '../../../constants';
import { toastError, tryParseJSON } from '../../../utils';
import { CInputCheckbox } from '../../migration/CInput';

const TermAndConditionPopup = () => {
     const dispatch = useDispatch();
     const location = useLocation();
     const userTerms = useSelector((state) => state.subscriber.userTerms);
     const termPublishes = useSelector((state) => state.subscriber.termPublishes);
     const setupGuideDataCompleted = useSelector((state) => state.subscriber.setupGuideDataCompleted);
     const packageId = useSelector((state) => state.subscriber.activeAccount.packageId);
     const showUpgradeAccountPopup = useSelector((state) => state.subscriber.showUpgradeAccountPopup);
     const { id: activeAccountId, reachTheEndOfSetup, secondId } = useSelector((state) => state.subscriber.activeAccount);

     const typeTC = 'Default';
     const termDefaultPublish = termPublishes && termPublishes.find((term) => term.type === typeTC);
     const userTermsDefault = userTerms && userTerms.filter((termAgree) => termAgree.type === typeTC);

     const [show, setShow] = useState(false);
     const [isLoading, setIsLoading] = useState(false);
     const [checkTerm, setCheckTerm] = useState(false);
     const [disableButton, setDisableButton] = useState(true);
     // const [disableCheckbox, setDisableCheckbox] = useState(true)

     const termAndConditionValidate = () => {
          if (activeAccountId && typeof termDefaultPublish !== 'undefined') {
               const termActiveAccount = userTermsDefault.filter((term) => term.accountId === activeAccountId);
               const termUserAgree = termActiveAccount.find((term) => term.termsAndConditionsId === termDefaultPublish.id);

               if (
                    typeof termUserAgree === 'undefined' &&
                    termDefaultPublish.type === typeTC &&
                    reachTheEndOfSetup &&
                    !showUpgradeAccountPopup &&
                    (location.pathname === `/${secondId}` || location.pathname === `/${secondId}/` || location.pathname.includes(SUBSCRIBER_MY_ACCOUNT_PATH.MASTERCLASS))
               ) {
                    setShow(true);
               } else {
                    setShow(false);
               }
          }
     };
     useEffect(termAndConditionValidate, [reachTheEndOfSetup, location.pathname, showUpgradeAccountPopup]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (show) {
               document.body.classList.add('no-scroll');
          } else {
               document.body.classList.remove('no-scroll');
          }
     }, [show]);

     useEffect(() => {
          if (activeAccountId) {
               callTokenApi(`${API_CLIENT_ACCOUNT_SETUP_GUIDE_DATA}/${activeAccountId}`, 'GET').then((response) => {
                    if (response.status === 200) {
                         const result = response.data.data || {};
                         dispatch(setDataSetupGuideDataCompleted(result));
                    } else {
                         toastError(response);
                    }
               });
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [activeAccountId]);

     // const checkContentScroll = (event) => {
     //     const termContent = event.target
     //     if (termContent.scrollHeight <= (termContent.scrollTop + 400)) {
     //         // setCheckTerm(true)
     //         // setDisableButton(false)
     //         setDisableCheckbox(false)
     //     }
     // }

     // const checkContentNoScroll = () => {
     //     const termContent = document.getElementById("termContent")
     //     if (termContent) {
     //         setTimeout(() => {
     //             if (termContent.scrollHeight === 400) {
     //                 setDisableCheckbox(false)
     //             }
     //         }, 100);
     //     }
     // }

     function onAgreeTermAndCondition() {
          localStorage.removeItem(SCHEDULE_DEMO);
          setIsLoading(true);
          setDisableButton(true);
          const data = {
               accountId: activeAccountId,
               termsAndConditionsId: termDefaultPublish.id,
               packageId: packageId,
               type: typeTC,
          };
          callTokenApi(`${API_CLIENT_ADD_USER_TERM}`, 'POST', data).then((response) => {
               if (response.status === 200) {
                    setShow(false);
                    setDisableButton(false);
                    dispatch(setUserTerms([...userTerms, data]));
                    // dispatch(setTour({ stepTour: 0, showTour: true }));
                    toast.success(response.data.message);
                    if (setupGuideDataCompleted && setupGuideDataCompleted.completedSteps && isEmpty(setupGuideDataCompleted.completedSteps)) {
                         dispatch(setShowRoadMap(true));
                         dispatch(setDataSetupGuideDataCompleted(null));
                         dispatch(setSetupGuideRoadmap(0));
                         dispatch(setShowSetupGuidePopup(true));
                         localStorage.setItem('showAnimationRoadmap', true);
                    }
               } else {
                    setDisableButton(false);
                    setIsLoading(false);
                    toastError(response);
               }
          });
     }
     // useEffect(checkContentNoScroll, [])

     if (!isEmpty(termDefaultPublish)) {
          termDefaultPublish.content = tryParseJSON(termDefaultPublish.content);
          const sanitizedTermDefaultPublishContentDescription = DOMPurify.sanitize(termDefaultPublish?.content?.description || '')
          return (
               <div className="confirm-save-popup term-and-condition">
                    <CModal visible={show} backdrop="static" alignment="center" portal={false}>
                         <CModalBody className="text-center confirm-save">
                              <div className="headline">
                                   <h2>{termDefaultPublish.content.headline}</h2>
                                   {termDefaultPublish.content.subHeadline && <h3>{termDefaultPublish.content.subHeadline}</h3>}
                              </div>
                              <div
                                   id="termContent"
                                   className="confirm-save-content"
                                   dangerouslySetInnerHTML={{ __html: sanitizedTermDefaultPublishContentDescription }}
                              ></div>
                              <div className="d-flex justify-content-between">
                                   <div className="term-checkbox">
                                        <CInputCheckbox
                                             name="termCheckbox"
                                             id="termCheckbox"
                                             checked={checkTerm}
                                             // disabled={disableCheckbox}
                                             onClick={() => setCheckTerm(!checkTerm)}
                                             onChange={() => setDisableButton(!disableButton)}
                                        />
                                        <label htmlFor="termCheckbox">
                                             {termDefaultPublish.content.checkboxText
                                                  ? termDefaultPublish.content.checkboxText
                                                  : 'Are you agree term and condition'}
                                        </label>
                                   </div>
                                   {termDefaultPublish.effectiveDate && (
                                        <div className="effective-date">
                                             <p>Effective Date: {termDefaultPublish.effectiveDate}</p>
                                        </div>
                                   )}
                              </div>
                              <div className="position-relative d-inline-block">
                                   <CButton className="btn-primary" onClick={() => onAgreeTermAndCondition()} disabled={disableButton}>
                                        {isLoading ? (
                                             <span className="dots-waiting">Waiting</span>
                                        ) : termDefaultPublish.content.buttonText ? (
                                             termDefaultPublish.content.buttonText
                                        ) : (
                                             'Confirm'
                                        )}
                                   </CButton>
                              </div>
                         </CModalBody>
                    </CModal>
               </div>
          );
     }

     return <></>;
};

export default TermAndConditionPopup;
