import React, { useContext, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { CButton, CCard, CCardBody } from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';

import { CHANGE_STEP_TYPE, GTMWizardBody, GTMWizardContext } from '.';
import CenterSpinner from '../../../../general/Loadings/CenterSpinner';
import { Popup } from '../../../../general/popup';
import { callTokenApi } from '../../../../../apiCaller';
import { toastError } from '../../../../../utils';
import { API_CLIENT_DESTINATIONS_GTM_BY_RULE, API_CLIENT_DESTINATIONS_GTM_CHANGES, GTM_ACTIONS, GTM_STATUSES_IN_LL, GTM_WIZARD_STEPS, GTM_WIZARD_TYPE } from '../../../../../constants';
import { useCancelToken } from '../../../../../helpers/customHooks';
import { setActiveAccount, setGTMWizard } from '../../../../../actions/subscriber';
import CLabel from '../../../../migration/CLabel';
import CFormGroup from '../../../../migration/CFormGroup';
import { CInputRadio } from '../../../../migration/CInput';

const GTMVerifyChanges = () => {
     const dispatch = useDispatch();
     const { stepsData, setStepsData, setCurrentStep } = useContext(GTMWizardContext);
     const activeAccount = useSelector(state => state.subscriber.activeAccount);
     const { currentContainer } = activeAccount.gtmInfo;
     const gtmWizard = useSelector(state => state.subscriber.gtmWizard);
     const [isLoading, setIsLoading] = useState(true);
     const [showLearnMorePopup, setShowLearnMorePopup] = useState(false);
     const [isPublishLearnMoreClicked, setIsPublishLearnMoreClicked] = useState(false);
     const { verifyChanges, gtmHasUnpublishedChanges, doPublish, workspaceName, gtmHasLLWorkspace } = stepsData;
     const getCancelToken = useCancelToken();

     const toggleLearnMorePopup = (isPublish) => {
          setIsPublishLearnMoreClicked(isPublish);
          setShowLearnMorePopup(show => !show)
     };

     const onBackBtnClicked = () => {
          setCurrentStep(null, CHANGE_STEP_TYPE.PREV);
     };

     const completeActions = () => {
          setCurrentStep(GTM_WIZARD_STEPS.gtmMakeChanges);
     };

     const onPublishRadioChanged = (e) => {
          setStepsData({ doPublish: e.target.value === 'yes' });
     };

     const handleGeneralActions = () => {
          if (verifyChanges.length === 0) {
               let postData = {
                    accountId: activeAccount.id,
                    container: {
                         containerId: currentContainer.containerId,
                         path: currentContainer.path,
                         publicId: currentContainer.publicId
                    },
                    name: gtmWizard.data.name,
                    type: gtmWizard.type,
                    action: 'Verify Changes',
                    isUpdating: gtmWizard.data.isUpdating
               };

               switch (gtmWizard.type) {
                    case GTM_WIZARD_TYPE.VARIABLE:
                         postData.variableListenerId = gtmWizard.data.listenerId;
                         break;
                    case GTM_WIZARD_TYPE.TRIGGER:
                    case GTM_WIZARD_TYPE.TRIGGER_CONVERSION:
                    case GTM_WIZARD_TYPE.DATA_ACTION:
                         if (gtmWizard.data.isLookupChild) {
                              postData.ruleId = gtmWizard.data.ruleId;
                              postData.isLookupChild = gtmWizard.data.isLookupChild;
                              postData.lookupChildId = gtmWizard.data.id;
                         } else {
                              postData.ruleId = gtmWizard.data.id;
                         }

                         break;
                    case GTM_WIZARD_TYPE.DESTINATION_CONVERSION:
                    case GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE:
                         postData.destinationConversion = gtmWizard.data;
                         break;
                    default:
               }

               callTokenApi(API_CLIENT_DESTINATIONS_GTM_CHANGES, 'POST', postData, getCancelToken())
                    .then(response => {
                         if (response) {
                              if (response.status === 200) {
                                   const {
                                        changes,
                                        existentVariableNames,
                                        gtmHasUnpublishedChanges,
                                        workspaceName,
                                        workspacePath,
                                        gtmHasLLWorkspace
                                        // existentTriggerNames
                                   } = response.data;

                                   let newStepsData = { verifyChanges: changes, gtmHasUnpublishedChanges, workspaceName, workspacePath, gtmHasLLWorkspace };
                                   let isExistent = false;

                                   if (Array.isArray(existentVariableNames) && existentVariableNames.length > 0 && gtmWizard.type === GTM_WIZARD_TYPE.VARIABLE) {
                                        newStepsData.existentVariableNames = existentVariableNames;
                                        isExistent = true;
                                   }

                                   // if (Array.isArray(existentTriggerNames) && existentTriggerNames.length > 0 && gtmWizard.type === GTM_WIZARD_TYPE.TRIGGER) {
                                   //      newStepsData.existentTriggerNames = existentTriggerNames;
                                   //      isExistent = true;
                                   // }

                                   setStepsData(newStepsData);

                                   if (isExistent) {
                                        setCurrentStep(GTM_WIZARD_STEPS.gtmExists);
                                   }
                              } else {
                                   toastError(response);

                                   if (response.data.noWritePermission) {
                                        dispatch(setGTMWizard({ show: false }));
                                   }

                                   if (response.data.message === 'Google Tag Manage connection requires re-authentication.') {
                                        setCurrentStep(GTM_WIZARD_STEPS.gtmConnection);
                                        activeAccount.gtmInfo = null;
                                        dispatch(setActiveAccount({ ...activeAccount }));
                                   }
                              }

                              setIsLoading(false);
                         }
                    });
          } else {
               setIsLoading(false);
          }
     };

     const handleDelete = () => {
          const gtmDeleteByRuleData = {
               accountId: activeAccount.id,
               ruleId: gtmWizard.data.id,
               type: gtmWizard.type,
               containerId: currentContainer.containerId,
               containerPath: currentContainer.path,
               action: GTM_ACTIONS.VERIFY_CHANGES
          };

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_BY_RULE, 'DELETE', gtmDeleteByRuleData)
               .then((response) => {
                    if (response.status === 200) {
                         const { changes, gtmHasUnpublishedChanges } = response.data;

                         setStepsData({ verifyChanges: changes, gtmHasUnpublishedChanges });
                    } else {
                         toastError(response);

                         if (response.data.noWritePermission) {
                              dispatch(setGTMWizard({ show: false }));
                         }
                    }

                    setIsLoading(false);
               })
     };

     const handlePause = () => {
          const gtmDeleteByRuleData = {
               accountId: activeAccount.id,
               ruleId: gtmWizard.data.id,
               type: gtmWizard.type,
               containerId: currentContainer.containerId,
               containerPath: currentContainer.path,
               action: GTM_ACTIONS.VERIFY_CHANGES,
               pause: gtmWizard.data.pause,
          };

          callTokenApi(API_CLIENT_DESTINATIONS_GTM_BY_RULE, 'POST', gtmDeleteByRuleData)
               .then((response) => {
                    if (response.status === 200) {
                         const { changes, gtmHasUnpublishedChanges } = response.data;

                         setStepsData({ verifyChanges: changes, gtmHasUnpublishedChanges });
                    } else {
                         toastError(response);

                         if (response.data.noWritePermission) {
                              dispatch(setGTMWizard({ show: false }));
                         }
                    }

                    setIsLoading(false);
               })
     };

     const handleFirstLoad = () => {
          if (gtmWizard.data.isUpdating) {
               return handleGeneralActions();;
          }

          switch (gtmWizard.data.status) {
               case GTM_STATUSES_IN_LL.MODIFIED_IN_LL:
                    handleGeneralActions();
                    break;
               case GTM_STATUSES_IN_LL.REMOVE_FROM_GTM:
                    handleDelete();
                    break;
               case GTM_STATUSES_IN_LL.PAUSE_FROM_GTM:
                    handlePause();
                    break;
               default:
                    handleGeneralActions();
          }
     };

     useEffect(handleFirstLoad, []); // eslint-disable-line react-hooks/exhaustive-deps
     let hideDisplayDontPublish = [GTM_STATUSES_IN_LL.REMOVE_FROM_GTM, GTM_STATUSES_IN_LL.PAUSE_FROM_GTM].includes(gtmWizard.data.status) ||
          (gtmWizard.type === GTM_WIZARD_TYPE.DESTINATION_CONVERSION && verifyChanges.find((item) => item.includes('Delete')));

     if (gtmWizard && gtmWizard.data.ecommerceEvent && gtmWizard.data.ecommerceEvent.length > 0) {
          if (gtmWizard.data.status === GTM_STATUSES_IN_LL.REMOVE_FROM_GTM || (gtmWizard.type === GTM_WIZARD_TYPE.DESTINATION_ECOMMERCE && verifyChanges.find((item) => item.includes('Delete')))) {
               hideDisplayDontPublish = true
          }
     }
     return (
          <GTMWizardBody onBackBtnClicked={onBackBtnClicked} >
               <CCard className="account-script verify-changes">
                    <CCardBody>
                         {
                              isLoading ? (
                                   <CenterSpinner />
                              ) : (
                                   <>
                                        <h3>Verify Changes</h3>
                                        <p className="mb-4">We're going to deploy your changes to GTM. Here are the changes we'll take.</p>
                                        <p className="mb-3">
                                             Inside{' '}
                                             <span className="inside-container">
                                                  {currentContainer.containerName}
                                             </span>{' '}
                                             we will
                                        </p>
                                        <div className="list-action">
                                             {
                                                  verifyChanges.map((change, i) => {
                                                       const sanitizedChange = DOMPurify.sanitize(change);
                                                      return (<div key={i} className="action" dangerouslySetInnerHTML={{ __html: sanitizedChange }}></div>)
                                                  })
                                             }
                                        </div>
                                        <div className="radio-group">
                                             <CFormGroup variant="custom-radio">
                                                  <CInputRadio id="publish-radio1" name="publishRadios" value="yes" onChange={onPublishRadioChanged} checked={stepsData.doPublish} />
                                                  <CLabel htmlFor="publish-radio1">Publish the changes in GTM.</CLabel>
                                                  <CButton type="button" className="btn-learn-more" onClick={() => toggleLearnMorePopup(true)}>
                                                       Learn more<i className="fal fa-angle-right"></i>
                                                  </CButton>
                                             </CFormGroup>
                                             {
                                                  !hideDisplayDontPublish && (
                                                       <CFormGroup variant="custom-radio">
                                                            <CInputRadio id="publish-radio2" name="publishRadios" value="no" onChange={onPublishRadioChanged} checked={typeof stepsData.doPublish === 'boolean' && !stepsData.doPublish }/>
                                                            <CLabel htmlFor="publish-radio2">Don't publish the changes.</CLabel>
                                                            <CButton type="button" className="btn-learn-more" onClick={() => toggleLearnMorePopup(false)}>
                                                                 Learn more<i className="fal fa-angle-right"></i>
                                                            </CButton>
                                                       </CFormGroup>
                                                  )
                                             }
                                        </div>
                                        <CButton
                                             type="button"
                                             className="btn-save"
                                             onClick={completeActions}
                                             disabled={typeof doPublish === 'undefined' && verifyChanges.length > 0}
                                        >
                                             COMPLETE THESE ACTIONS
                                        </CButton>
                                   </>
                              )
                         }
                    </CCardBody>
               </CCard>
               <Popup className="learn-more account-script-popup" showHeader={true} show={showLearnMorePopup} onClose={() => setShowLearnMorePopup(false)}>
                    {
                         gtmHasUnpublishedChanges ? (
                              isPublishLearnMoreClicked ? (
                                   <>
                                        <p>
                                             We'll publish our changes to ensure everything is live on your website.
                                             This will create a new version in GTM which will allow you to easily revert if needed.
                                        </p>
                                        {
                                             gtmHasLLWorkspace ? (
                                                  <p>
                                                       We found an unpublished workspace called <strong>{workspaceName}</strong> that you've been using to push changes into.
                                                       We'll make these changes to that workspace and publish the entire workspace.
                                                  </p>
                                             ) : (
                                                  <p>
                                                       We did find that your container already has some unpublished changes, and we don't want to publish those.
                                                       As a result, we'll create a unique workspace so that only our changes will be published.
                                                       You can then merge these changes into your other workspace at some point in the future.
                                                  </p>
                                             )
                                        }
                                   </>
                              ) : (
                                   <>
                                        <p>
                                             We'll create the elements inside your GTM account, and you'll be required to publish them manually.
                                             We recommend publishing soon to ensure that ListenLayer and GTM remain in sync.
                                        </p>
                                        {
                                             gtmHasLLWorkspace ? (
                                                  <p>
                                                       We found an unpublished workspace called <strong>{workspaceName}</strong> that you've been using to push changes into.
                                                       We'll make these changes to that workspace but it won't be published - you'll need to review the changes inside GTM and publish the workspace there.
                                                  </p>
                                             ) : (

                                                  <p>
                                                       We did find that your container already has some unpublished changes, and we don't want to publish those.
                                                       As a result, we'll create a unique workspace so that only these changes can be isolated.
                                                       Review the new workspace and publish it when you are ready.
                                                  </p>
                                             )
                                        }
                                   </>
                              )
                         ) : (
                              isPublishLearnMoreClicked ? (
                                   <>
                                        <p>
                                             We'll publish our changes to ensure everything is live on your website.
                                             This will create a new version in GTM which will allow you to easily revert if needed.
                                        </p>
                                        <p>
                                             We've checked your GTM account and found that there are no other unpublished changes in the container,
                                             which means only our changes are going live.
                                        </p>
                                   </>
                              ) : (
                                   <>
                                        <p>
                                             We'll create the elements inside your GTM account, and you'll be required to publish them manually.
                                             We recommend publishing soon to ensure that ListenLayer and GTM remain in sync.
                                        </p>
                                        <p>
                                             We've checked your GTM account and found that there are no other unpublished changes in the container,
                                             which means only our changes are going live.
                                        </p>
                                   </>
                              )
                         )
                    }
               </Popup>
          </GTMWizardBody>
     )
};

export default GTMVerifyChanges;