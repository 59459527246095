import { REPORT_DATA_TYPES } from "../../../../../../constants";
import CFormGroup from "../../../../../migration/CFormGroup";
import CLabel from "../../../../../migration/CLabel";
import Select from 'react-select';
import dayjs from 'dayjs';
import CustomDatePicker from "./SelectDateRange";
import RollingDateRange from "./RollingDateRange";
import { useSelector } from "react-redux";


const DateRangeConfig = (props) => {

     const { values, data, scoped, reportName, setFieldValue, setFieldError, errors } = props;

     const currentScoped = data?.scoped || scoped;
     const currentReportName = data?.reportName || reportName;

     const reportDetailData = REPORT_DATA_TYPES[currentReportName];
     const dateScopes =
          (reportDetailData && (reportDetailData.dateScopes || (reportDetailData[currentScoped] && reportDetailData[currentScoped].dateScopes))) || [];
     const dateScopeOptions = dateScopes.map((item) => ({ label: item, value: item }));

     const dateRangeOptions = [
          { value: 'noRange', label: 'No Automatic Date Range' },
          { value: 'staticRange', label: 'Static Date Range' },
          { value: 'rollingRange', label: 'Rolling Date Range' },
     ];

     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dayjsNow = dayjs().tz(activeAccount.timeZone);

     let defaultDateRange = {
          selection: {
               startDate: dayjsNow.subtract(1, 'day'),
               endDate: dayjsNow.subtract(0, 'day'),
          },
     };

     const handleChangeDateRangeStatic = (data) => {
          setFieldValue('dateRangeConfig.dateRanges', data)
     }

     return <>
          <CFormGroup className='d-flex align-items-center mb-3'>
               <CLabel className="mr-3 d-block" style={{fontWeight: '500'}}>
                    Date Range Options:
               </CLabel>
               <Select
                    className='width_200'
                    classNamePrefix='react-select'
                    options={dateRangeOptions}
                    value={dateRangeOptions.filter((option) => option.value === values.dateRangeConfig?.type)}
                    onChange={(option) => {
                         setFieldValue('dateRangeConfig.type', option.value)

                         if (option.value === 'noRange') {
                              setFieldValue('dateRangeConfig.dateRanges', null)
                              setFieldValue('dateRangeConfig.dateScope', null)
                              setFieldValue('dateRangeConfig.rolling', null)
                         }

                         if (option.value === 'staticRange') {
                              setFieldValue('dateRangeConfig.dateRanges', defaultDateRange)
                              setFieldValue('dateRangeConfig.rolling', null)
                         }

                         if (option.value === 'rollingRange') {
                              setFieldValue('dateRangeConfig.rolling.type', 'previous_week_sun_sat')
                              setFieldValue('dateRangeConfig.dateRanges', null)
                         }
                    }}
               />
          </CFormGroup>
          {values.dateRangeConfig?.type === 'staticRange' && (
               <CFormGroup>
                    <CustomDatePicker handleChangeDateRange={handleChangeDateRangeStatic} value={values.dateRangeConfig.dateRanges} />
               </CFormGroup>
          )}

          {values.dateRangeConfig?.type === 'rollingRange' && (
               <RollingDateRange values={values} setFieldValue={setFieldValue} setFieldError={setFieldError} errors={errors} defaultDateRange={defaultDateRange}/>
          )}

          {values.dateRangeConfig?.type !== 'noRange' && dateScopeOptions && dateScopeOptions.length > 0 && (
               <CFormGroup>
                    <CLabel className="mr-5 d-block">
                         Apply date range to:
                    </CLabel>
                    <Select
                         className='width_200'
                         classNamePrefix='react-select'
                         options={dateScopeOptions}
                         value={dateScopeOptions.filter((option) => option.value === values.dateRangeConfig.dateScope)}
                         onChange={(option) => {
                              setFieldValue('dateRangeConfig.dateScope', option.value)
                         }}
                    />
               </CFormGroup>
          )}
     </>
}

export default DateRangeConfig