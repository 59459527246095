import React, { useContext, useEffect } from 'react';
import { CForm, CCard, CCardBody } from '@coreui/react';
import { useDispatch } from 'react-redux';

import { CreateAccountBody, CreateAccountContext, STEPS } from '../CreateAccount';
import { callTokenApi } from '../../../../../../apiCaller';
import { API_CLIENT_ACCOUNT_COMPLETED_STEPS, API_CLIENT_ACCOUNT_DESTINATIONS, API_UPDATE_CONSENT_LISTENER } from '../../../../../../constants';
import CommonLottie from '../../../../../general/lottie/CommonLottie';
import wwwAnimationData from '../../../../../../assets/lottie-files/www.json';
import { enableManyListeners, handleCrawlDomain, setConsentListenerAccount } from '../../../../../../actions/subscriber';
import { getSessionKeySignupCurrentStep, getSessionKeySignupDestinations, getSessionKeySignupListeners, toastError } from '../../../../../../utils';
import useSaveCurrentStep from '../useSaveCurrentStep';
import uniq from 'lodash/uniq';
import CFormGroup from '../../../../../migration/CFormGroup';

const ConfiguringAccount = () => {
     const dispatch = useDispatch();
     const { account, stepsData, setStepsData, setCurrentStep, setAccount } = useContext(CreateAccountContext);
     const { allSelectedListeners, selectedWeatherFeatures, selectedConsentListener, allSelectedDestinations } = stepsData;

     useSaveCurrentStep();

     const crawlDomain = () => {
          const accountCreatedId = localStorage['accountCreatedId'];
          let domain = stepsData.domain;
          const accountDomains = account ? account.domains : [];

          if (!stepsData.domain && accountDomains && accountDomains.length) {
               domain = accountDomains[0].name;

               accountDomains[0].isCrawling = true;

               setAccount({
                    ...account,
                    domains: accountDomains,
               });
          }

          dispatch(handleCrawlDomain({ domain, accountId: accountCreatedId, account, setAccount }));
     };

     useEffect(() => {
          const newAccount = {
               ...account,
               completedSteps: uniq([...account.completedSteps, STEPS.chooseDestination, STEPS.configureMeasurement]),
          };

          setAccount(newAccount);
          if(!account.lastDateCrawl) {
               crawlDomain();
          }
     }, []); // eslint-disable-line

     // Old, Do this if this screen if after consent screen
     // const goToNextStep = () => {
     //      setStepsData({ listenerConfigured: true });

     //      if (consentEnabled) {
     //           const foundPackage = packages.find(el => el.id === stepsData.defaultPackageId);

     //           // Select paid plan from pricing page and enable consent listener should display Paid Account screen
     //           if (account.packagePricePerMonth === 0 && (!foundPackage || foundPackage.pricePerMonth === 0)) {
     //                setCurrentStep(STEPS.accountPackage);
     //                return;
     //           }
     //      }

     //      setCurrentStep(STEPS.configureMeasurement, CHANGE_STEP_TYPE.mainStepNext);
     // }

     // Do this if this screen is after selecting plan

     const goToNextStep = () => {
          sessionStorage.removeItem(getSessionKeySignupListeners(account.id));
          sessionStorage.removeItem(getSessionKeySignupDestinations(account.id));
          sessionStorage.removeItem(getSessionKeySignupCurrentStep(account.id));
          setCurrentStep(STEPS.accountSetup);
     };

     const handleNoListener = async () => {
          await callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_STEPS, 'PUT', { accountId: account.id, step: STEPS.configureMeasurement });
          goToNextStep();
     };

     const handleConsentListener = async () => {
          if (selectedConsentListener && selectedConsentListener !== `don't see tool`) {
               let consentListener = { consentTool: selectedConsentListener };
               const data = {
                    consentListener,
                    accountId: account.id,
               };
               const response = await callTokenApi(API_UPDATE_CONSENT_LISTENER, 'POST', data);

               if (response.status === 200) {
                    setAccount((acc) => ({ ...acc, consentListener }));
                    dispatch(setConsentListenerAccount({ consentListener: response.data.consentListener }));
               } else {
                    toastError(response);
               }
          }
     };

     const handleEnableDestination = async () => {
          const data = {
               accountId: account.id,
               destinations: allSelectedDestinations,
          };
          await callTokenApi(`${API_CLIENT_ACCOUNT_DESTINATIONS}all`, 'POST', data);
          await callTokenApi(API_CLIENT_ACCOUNT_COMPLETED_STEPS, 'PUT', { accountId: account.id, step: STEPS.chooseDestination });
     };

     const configureAccount = () => {
          const process = async () => {
               if (Object.keys(allSelectedListeners).length === 0 && allSelectedDestinations.length === 0) {
                    goToNextStep();
                    return;
               }

               await handleEnableDestination();

               let listenerIds = [];

               Object.entries(allSelectedListeners).forEach(([_, value]) => {
                    // Enable not premium listeners, then if user selects paid plan, process enable selected premium listeners
                    value.forEach(({ id }) => {
                         // if (!premium) {
                         listenerIds.push(id);
                         // }
                    });

                    // listenerIds = [...listenerIds, ...value.map(({ id, premium }) => {
                    //      if (premium) {
                    //           numberOfPremiumToEnable++; // Increase number of premium for selecting package later
                    //      }

                    //      return id;
                    // })];
               });

               // if (account.packageNumberOfPremium === -1 || totalPremium <= account.packageNumberOfPremium) {
               //      // Enable premium listeners if total premium <= the number that account package allows
               //      listenerIds = [...listenerIds, ...selectedPremiumListenerIds];
               // }

               await handleConsentListener();

               if (listenerIds.length > 0) {
                    const successNext = () => {
                         // Wait for finishing publish version
                         setTimeout(() => {
                              setStepsData({ shouldFetchListeners: true });
                              goToNextStep();
                              setAccount((acc) => ({ ...acc, numberOfListeners: listenerIds.length }));
                         }, 3000);
                    };

                    // Maybe wait for setting version history of previous APIs
                    setTimeout(() => {
                         dispatch(
                              enableManyListeners({
                                   accountId: account.id,
                                   listenerIds,
                                   weatherFeatures: selectedWeatherFeatures,
                                   shouldPublish: true,
                                   successNext,
                                   updateCompletedSteps: true,
                              }),
                         );
                    }, 2000);
               } else {
                    await handleNoListener();
               }
          };

          process();
     };

     useEffect(configureAccount, []); // eslint-disable-line react-hooks/exhaustive-deps

     return (
          <>
               {Object.keys(allSelectedListeners).length > 0 && (
                    <CreateAccountBody>
                         <CCard className="account-configuring checking">
                              <CCardBody>
                                   <CForm>
                                        <CFormGroup>
                                             <h3>Configuring Your Account</h3>
                                             <p>Please wait while we configure your account settings.</p>
                                             <CommonLottie animationData={wwwAnimationData} />
                                             <span className="foot-note mb-2">
                                                  Please do not refresh this page. Configuration can take up to a couple of minutes.
                                             </span>
                                        </CFormGroup>
                                   </CForm>
                              </CCardBody>
                         </CCard>
                    </CreateAccountBody>
               )}
          </>
     );
};

export default ConfiguringAccount;
