import { CButton, CModal, CModalBody } from '@coreui/react';
import DOMPurify from 'dompurify';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setUserTerms } from '../../../actions/subscriber';
import { callTokenApi } from '../../../apiCaller';
import { API_CLIENT_ADD_USER_TERM } from '../../../constants';
import { toastError, tryParseJSON } from '../../../utils';
import { CInputCheckbox } from '../../migration/CInput';

const TermAndConditionMasterClassPopup = () => {
     const typeTC = 'Masterclass';
     const dispatch = useDispatch();
     const termPublishes = useSelector((state) => state.subscriber.termPublishes);
     const termMCPublish = termPublishes && termPublishes.find((term) => term.type === typeTC);
     const userTerms = useSelector((state) => state.subscriber.userTerms);
     const userTermsMC =
          userTerms &&
          userTerms.filter((termAgree) => termAgree.type === typeTC && (termAgree.accountId === null || typeof termAgree.accountId === 'undefined'));
     const [show, setShow] = useState(false);
     const [isLoading, setIsLoading] = useState(false);
     const userCourse = useSelector((state) => state.theme.user.userCourse);
     const [disableButton, setDisableButton] = useState(true);
     const [checkTerm, setCheckTerm] = useState(false);

     const termAndConditionValidate = () => {
          if (userCourse.hasCourse && typeof termMCPublish !== 'undefined') {
               const termUserAgree = userTermsMC.find((termAgree) => termAgree.termsAndConditionsId === termMCPublish.id);
               if (typeof termUserAgree === 'undefined' && termMCPublish.type === typeTC) {
                    setShow(true);
               }
          }
     };

     const onAgreeTermAndCondition = () => {
          setIsLoading(true);
          setDisableButton(true);
          const data = {
               termsAndConditionsId: termMCPublish.id,
               type: typeTC,
          };
          callTokenApi(`${API_CLIENT_ADD_USER_TERM}`, 'POST', data).then((response) => {
               if (response.status === 200) {
                    setShow(false);
                    setDisableButton(false);
                    dispatch(setUserTerms([...userTerms, data]));
                    toast.success(response.data.message);
               } else {
                    setDisableButton(false);
                    setIsLoading(false);
                    toastError(response);
               }
          });
     };

     useEffect(termAndConditionValidate, []); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (show) {
               document.body.classList.add('no-scroll');
          } else {
               document.body.classList.remove('no-scroll');
          }
     }, [show]);

     if (!isEmpty(termMCPublish)) {
          termMCPublish.content = tryParseJSON(termMCPublish.content);
          const sanitizedTermMCPublishContentDescription = DOMPurify.sanitize(termMCPublish?.content?.description || '')
          return (
               <div className="confirm-save-popup term-and-condition">
                    <CModal visible={show} backdrop="static" alignment="center" portal={false}>
                         <CModalBody className="text-center confirm-save">
                              <div className="headline">
                                   <h2>{termMCPublish.content.headline}</h2>
                                   {termMCPublish.content.subHeadline && <h3>{termMCPublish.content.subHeadline}</h3>}
                              </div>
                              <div
                                   id="termContent"
                                   className="confirm-save-content"
                                   dangerouslySetInnerHTML={{ __html: sanitizedTermMCPublishContentDescription }}
                              ></div>
                              <div className="d-flex justify-content-between">
                                   <div className="term-checkbox">
                                        <CInputCheckbox
                                             name="termCheckbox"
                                             id="termCheckbox"
                                             checked={checkTerm}
                                             onClick={() => setCheckTerm(!checkTerm)}
                                             onChange={() => setDisableButton(!disableButton)}
                                        />
                                        <label htmlFor="termCheckbox">
                                             {termMCPublish.content.checkboxText
                                                  ? termMCPublish.content.checkboxText
                                                  : 'Are you agree term and condition'}
                                        </label>
                                   </div>
                                   {termMCPublish.effectiveDate && (
                                        <div className="effective-date">
                                             <p>Effective Date: {termMCPublish.effectiveDate}</p>
                                        </div>
                                   )}
                              </div>
                              <div className="position-relative d-inline-block">
                                   <CButton className="btn-primary" onClick={() => onAgreeTermAndCondition()} disabled={disableButton}>
                                        {isLoading ? (
                                             <span className="dots-waiting">Waiting</span>
                                        ) : termMCPublish.content.buttonText ? (
                                             termMCPublish.content.buttonText
                                        ) : (
                                             'Confirm'
                                        )}
                                   </CButton>
                              </div>
                         </CModalBody>
                    </CModal>
               </div>
          );
     }

     return <></>;
};

export default TermAndConditionMasterClassPopup;
