import CIcon from "@coreui/icons-react";
import { CButton, CCard, CSpinner } from "@coreui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import FormRequiringTableRow from "./FormRequiringTableRow";
import { callTokenApi } from "../../../../../../apiCaller";
import { toastError, useOutsideHandling } from "../../../../../../utils";
import {
     API_CLIENT_UPDATE_FORM_CATEGORIES,
     API_CLIENT_UPDATE_INHERIT_PARENT,
     API_CLIENT_UPDATE_PUSH_EVENTS,
     COMPONENT_NAME,
} from "../../../../../../constants";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
     setFormRequiringAction,
     handleFetchCountFormsRequiring,
     handleFetchCountFormsInventory,
     handleFetchCountFormsIgnored,
     setFormInventory,
     setShowIgnoredForm,
     setFormInherit,
     setFormRedirect,
     setAllFormsInventory,
     setAllFormsRequiring,
     handleApplyChildForms,
     handleApplyRedirectForms,
     setFormRequiringEdit,
     setFormIgnored,
     setAllFormsIgnore,
     setSubscriberState,
     setFormInventoryEdit,
     setFormsDraft,
     setLoadFormChildRequiring,
     setFilterFormCategories,
     setFilterEditFormCategories,
} from "../../../../../../actions/subscriber";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import { ConfirmSaveChange } from "../../../../../general/popup";
import { setFlexibleModal } from "../../../../../../actions/common";
import CenterSpinner from "../../../../../general/Loadings/CenterSpinner";
import { ClickInfoIconTooltip } from "../../../../../general/custom-tooltips";
import MultiFormsRequiring from "./MultiForms";
import CLabel from "../../../../../migration/CLabel";
import CFormGroup from "../../../../../migration/CFormGroup";
import { CInputCheckbox } from "../../../../../migration/CInput";

const useDraftForms = ({ forms }) => {
     const dispatch = useDispatch();
     const formRequiringEdit = useSelector((state) => state.subscriber.formRequiringEdit);

     // Push draft forms to formRequiringEdit to save changes
     useEffect(() => {
          if (forms && forms.length > 0) {
               const newFormRequiringEdit = [...formRequiringEdit];

               forms.forEach((item) => {
                    if (item.issue === "isDrafted" && formRequiringEdit.every((el) => el.id !== item.id)) {
                         newFormRequiringEdit.push(item);
                    }
               });

               if (newFormRequiringEdit.length !== formRequiringEdit.length) {
                    dispatch(setSubscriberState({ formRequiringEdit: newFormRequiringEdit }));
               }
          }
     }, [forms]); // eslint-disable-line
};

const FormRequiringLayout = ({ className = "", tableClassName = "", forms, onLoadMore }) => {
     const dispatch = useDispatch();
     const formsRequiring = useSelector((state) => state.subscriber.formsRequiring);
     const allFormsRequiring = useSelector((state) => state.subscriber.allFormsRequiring);
     const formRequiringEdit = useSelector((state) => state.subscriber.formRequiringEdit);
     const countFormRequiring = useSelector((state) => state.subscriber.countFormRequiring);
     const requiringCountLoading = useSelector((state) => state.subscriber.requiringCountLoading);
     const showIgnoredForm = useSelector((state) => state.subscriber.showIgnoredForm);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const applyChild = useSelector((state) => state.subscriber.applyChild);
     const applyRedirect = useSelector((state) => state.subscriber.applyRedirect);
     const isLoading = useSelector((state) => state.subscriber.requiringLoading);
     const requiringShowPaging = useSelector((state) => state.subscriber.requiringShowPaging);
     const isLoadingSettings = useSelector((state) => state.subscriber.requiringSettingsLoading);
     const requiringFormsLoading = useSelector((state) => state.subscriber.requiringFormsLoading);
     const requiringNumberPerpage = useSelector((state) => state.subscriber.requiringNumberPerpage);
     const requiringDisableNextPage = useSelector((state) => state.subscriber.requiringDisableNextPage);
     const formCategoriesRules = useSelector((state) => state.subscriber.formCategoriesRules);
     const [submitLoading, setSubmitLoading] = useState(false);
     const [updateModal, setUpdateModal] = useState(false); // false: not show
     const [errors, setErrors] = useState([]);
     const [tooltipSettings, setTooltipSettings] = useState(false);
     const [redirectTempFormData, setRedirectTempFormData] = useState(false);
     const statusRefSettings = useRef(null);
     const itemsPerPage = 12;
     useDraftForms({ forms });

     const listChildRequiring = useSelector((state) => {
          return forms?.length > 0
               ? forms.map(item => item.id)
                      .reduce((result, key) => {
                           result.push(state.subscriber[`requiring${key}`]);
                           return result.flat().filter(i => i !== undefined && i !== null);
                      }, [])
               : [];
     });
     const listChildIgnored = useSelector((state) => {
          return forms?.length > 0
          ? forms.map(item => item.id)
                 .reduce((result, key) => {
                      result.push(state.subscriber[`ignored${key}`]);
                      return result.flat().filter(i => i !== undefined && i !== null);
                 }, [])
          : [];
     });

     const rulesDefault = formCategoriesRules ? formCategoriesRules.filter((rule) => rule.name === "Categorize Forms") : [];

     let formsEdit = forms && forms.every((el) => el.issue !== "isDrafted"); // Enable save changes button if has at least 1 draft form

     if (formsEdit) {
          formsEdit =
               formRequiringEdit.length > 0
                    ? formRequiringEdit.every((item) => {
                           if (item.issue === "isDrafted") {
                                return false;
                           }

                           const initForm = allFormsRequiring && [...allFormsRequiring, ...listChildRequiring, ...listChildIgnored].find((form) => form.id === item.id);

                           if (initForm) {
                                return (
                                     item.categoryId === initForm.categoryId &&
                                     item.inheritParent === initForm.inheritParent &&
                                     item.name === initForm.name
                                );
                           }
                           return false;
                      })
                    : true;
     }

     const treeFamilyOfForms = forms
          ? forms.map((form) => {
                 const formExisted = formRequiringEdit.find((item) => item.id === form.id);

                 if (formExisted) {
                      return { ...form, ...formExisted };
                 }
                 return form;
            })
          : [];

     const checkValidate = () => {
          let newErrors = [];
          const newForms = formRequiringEdit && formRequiringEdit.length > 0 ? cloneDeep(formRequiringEdit) : cloneDeep(forms);

          for (let i = 0; i < newForms.length; i++) {
               if (!newForms[i].parentId) {
                    if (newForms[i].categoryId && !newForms[i].name) {
                         newErrors = [...newErrors, { id: newForms[i].id, invalidName: true }];
                    } else if (newForms[i].name && !newForms[i].categoryId) {
                         newErrors = [...newErrors, { id: newForms[i].id, invalidCategory: true }];
                    } else {
                         newErrors = [...newErrors];
                    }
               } else {
                    const formParent = newForms.find((form) => form.id === newForms[i].parentId);
                    if (formParent && newForms[i].inheritParent) {
                         if (formParent.categoryId && !formParent.name) {
                              newErrors = [...newErrors, { id: formParent.id, invalidName: true }];
                         } else if (formParent.name && !formParent.categoryId) {
                              newErrors = [...newErrors, { id: formParent.id, invalidCategory: true }];
                         } else {
                              newErrors = [...newErrors];
                         }
                    } else {
                         if (newForms[i].categoryId && !newForms[i].name) {
                              newErrors = [...newErrors, { id: newForms[i].id, invalidName: true }];
                         } else if (newForms[i].name && !newForms[i].categoryId) {
                              newErrors = [...newErrors, { id: newForms[i].id, invalidCategory: true }];
                         } else {
                              newErrors = [...newErrors];
                         }
                    }
               }
          }

          if (newErrors.length > 0) {
               setErrors(newErrors);
               toast.error("Some fields are in error!");
          } else {
               setUpdateModal(true);
          }
     };

     const onSubmit = () => {
          setSubmitLoading(true);

          for (let i = 0; i < formRequiringEdit.length; i++) {
               const updateIgnored = allFormsRequiring.some(
                    (form) => form.id === formRequiringEdit[i].parentId && form.issue === "isIgnored" && formRequiringEdit[i].inheritParent
               );

               if (!formRequiringEdit[i].hasChild && formRequiringEdit[i].name && formRequiringEdit[i].categoryId) {
                    formRequiringEdit[i].issue = "";
               }

               if (formRequiringEdit[i].hasChild && formRequiringEdit[i].name && formRequiringEdit[i].categoryId) {
                    formRequiringEdit[i].issue = "isCategorized";
               }

               if (formRequiringEdit[i].issue === "isDrafted" && !formRequiringEdit[i].name && !formRequiringEdit[i].categoryId) {
                    formRequiringEdit[i].issue = "isAttention";
               }

               if (
                    !formRequiringEdit[i].name &&
                    !formRequiringEdit[i].categoryId &&
                    formRequiringEdit[i].issue !== "isIgnored" &&
                    formRequiringEdit[i].issue !== "isDrafted"
               ) {
                    formRequiringEdit[i].issue = "isAttention";
               }

               if (updateIgnored) {
                    formRequiringEdit[i].issue = "isIgnored";
               }
          }

          //Update form categories
          if (formRequiringEdit && formRequiringEdit.length > 0) {
               const newDataRequest = {
                    accountId: activeAccount.id,
                    data: formRequiringEdit,
                    type: "requiring",
               };
               callTokenApi(API_CLIENT_UPDATE_FORM_CATEGORIES, "PUT", newDataRequest)
                    .then((response) => {
                         if (response.status === 200) {
                              const { countChild } = response.data;
                              const newForms = [];
                              const uniqByParentId = uniqBy(formRequiringEdit, "parentId");
                              const newFormRequiringEdit = formRequiringEdit && formRequiringEdit.filter((form) => form.hasChild);
                              uniqByParentId.forEach((item) => {
                                   const existed = allFormsRequiring && allFormsRequiring.find((form) => form.id === item.parentId);
                                   if (existed && newFormRequiringEdit.every((form) => form.id !== existed.id)) {
                                        newFormRequiringEdit.push(existed);
                                   }
                              });

                              newFormRequiringEdit.forEach((item) => {
                                   dispatch(
                                        setSubscriberState({
                                             [`requiring${item.id}`]: null,
                                        })
                                   );

                                   dispatch(
                                        setSubscriberState({
                                             [item.id]: null,
                                        })
                                   );

                                   dispatch(setLoadFormChildRequiring(item.id));
                              });

                              formRequiringEdit.forEach((item) => {
                                   const existed = countChild && countChild.find((form) => form.id === item.id);
                                   if (existed) {
                                        item.count = existed.count || 0;
                                   }

                                   // if (item.hasChild) {
                                   //      const oldForm = allFormsRequiring && allFormsRequiring.find((form) => form.id === item.id);
                                   //      if ((!item.name || !item.categoryId) && oldForm && oldForm.name && oldForm.categoryId) {
                                   //           dispatch(
                                   //                setSubscriberState({
                                   //                     [`requiring${item.id}`]: null,
                                   //                })
                                   //           );
                                   //           dispatch(setLoadFormChildRequiring(item.id));
                                   //      }
                                   // }
                              });

                              formsRequiring.forEach((item) => {
                                   const existed = formRequiringEdit.find((form) => form.id === item.id);

                                   if (existed) {
                                        if (
                                             (!existed.name && !existed.categoryId && (existed.count > 0 || !existed.count)) ||
                                             (existed.hasChild && existed.count > 0)
                                        ) {
                                             newForms.push({ ...item, ...existed });
                                        }
                                   } else {
                                        newForms.push(item);
                                   }
                              });

                              const newAllForms = [];

                              allFormsRequiring.forEach((item) => {
                                   const existed = formRequiringEdit.find((form) => form.id === item.id);
                                   const childIgnored = formRequiringEdit.find(
                                        (form) => !form.hasChild && form.parentId === item.id && form.inheritParent && form.issue === "isIgnored"
                                   );

                                   if (existed) {
                                        if (
                                             (!existed.name && !existed.categoryId && (existed.count > 0 || !existed.count)) ||
                                             (existed.hasChild && existed.count > 0)
                                        ) {
                                             newAllForms.push({ ...item, ...existed });
                                        }
                                   } else if (!childIgnored || !countChild.some((child) => child.id === childIgnored.parentId && child.count === 0)) {
                                        newAllForms.push(item);
                                   }
                              });

                              // if (className === 'viewAllRequiringAction') {
                              //      if (newAllForms.length === 0) {
                              //           let newNumberPage = 0;

                              //           if (requiringNumberPerpage > 0) {
                              //                newNumberPage = requiringNumberPerpage - 1;
                              //           }
                              //           dispatch(handleFetchFormsRequiring(20, true, newNumberPage));
                              //      }
                              // }

                              setRedirectTempFormData(!redirectTempFormData);

                              dispatch(setAllFormsRequiring(newAllForms));
                              if (requiringNumberPerpage === 0) {
                                   dispatch(setFormRequiringAction(newAllForms.slice(0, itemsPerPage)));
                              }
                              // reset form inventory
                              dispatch(setFormInventory(null));
                              dispatch(setAllFormsInventory(null));
                              // reset form ignored
                              dispatch(setFormIgnored(null));
                              dispatch(setAllFormsIgnore(null));
                              //reset form edit
                              dispatch(setFormRequiringEdit(null));
                              dispatch(setFormInventoryEdit(null));
                              dispatch(setFormsDraft(null));
                              // fetch count
                              dispatch(handleFetchCountFormsRequiring());
                              dispatch(handleFetchCountFormsIgnored(null, activeAccount));
                              dispatch(handleFetchCountFormsInventory(null, activeAccount));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));

                              if (rulesDefault && rulesDefault.length === 0) {
                                   dispatch(setSubscriberState({ formCategoriesRules: null }));
                              }
                         } else {
                              toastError(response);
                         }
                    })
                    .finally(() => {
                         setUpdateModal(false);
                         setSubmitLoading(false);
                    });
          }
     };

     const onClickShowAll = () => {
          setErrors([]);

          dispatch(
               setFlexibleModal({
                    show: true,
                    component: COMPONENT_NAME.FORM_REQUIRING_ACTION,
               })
          );
     };

     const clickOutsideTimestamps = useCallback(() => {
          setTooltipSettings(false);
     }, []);

     useOutsideHandling(statusRefSettings, clickOutsideTimestamps);

     const handleShowIgnoredForm = () => {
          dispatch(setShowIgnoredForm(!showIgnoredForm));
     };

     const handleApplyChild = () => {
          dispatch(setSubscriberState({ requiringSettingsLoading: true }));
          dispatch(setFormInherit(!applyChild));

          //Update isInheritParent
          if (!applyChild !== activeAccount.isInheritParent) {
               const dataInheritParent = {
                    accountId: activeAccount.id,
                    isInheritParent: !applyChild,
               };

               callTokenApi(API_CLIENT_UPDATE_INHERIT_PARENT, "PUT", dataInheritParent)
                    .then((response) => {
                         if (response.status === 200) {
                              const newActiveAccount = { ...activeAccount, isInheritParent: !applyChild };
                              dispatch(setSubscriberState({ activeAccount: newActiveAccount }));

                              dispatch(handleApplyChildForms(!applyChild, "requiring", setErrors));
                              // reset form inventory
                              dispatch(setFormInventory(null));
                              dispatch(setAllFormsInventory(null));
                              dispatch(setFormInventoryEdit(null));
                              // fetch count
                              dispatch(handleFetchCountFormsRequiring());
                              dispatch(handleFetchCountFormsIgnored(null, activeAccount));
                              dispatch(handleFetchCountFormsInventory(null, activeAccount));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));
                         } else {
                              toastError(response);
                              dispatch(setSubscriberState({ requiringSettingsLoading: false }));
                         }
                    })
                    .finally(() => {
                         dispatch(setSubscriberState({ requiringSettingsLoading: false }));
                    });
          }
     };

     const handleApplyRedirect = () => {
          dispatch(setSubscriberState({ requiringSettingsLoading: true }));
          dispatch(setFormRedirect(!applyRedirect));

          //Update isPushEvents
          if (!applyRedirect !== activeAccount.isPushEvents) {
               const dataPushEvents = {
                    accountId: activeAccount.id,
                    isPushEvents: !applyRedirect,
               };

               callTokenApi(API_CLIENT_UPDATE_PUSH_EVENTS, "PUT", dataPushEvents)
                    .then((response) => {
                         if (response.status === 200) {
                              const newActiveAccount = { ...activeAccount, isPushEvents: !applyRedirect };
                              dispatch(setSubscriberState({ activeAccount: newActiveAccount }));

                              dispatch(handleApplyRedirectForms(!applyRedirect, "requiring"));
                              setRedirectTempFormData(!redirectTempFormData);
                              // reset form inventory
                              dispatch(setFormInventory(null));
                              dispatch(setAllFormsInventory(null));
                              dispatch(setFormInventoryEdit(null));
                              //reset filters
                              dispatch(setFilterFormCategories([]));
                              dispatch(setFilterEditFormCategories(null));
                         } else {
                              toastError(response);
                              dispatch(setSubscriberState({ requiringSettingsLoading: false }));
                         }
                    })
                    .finally(() => {
                         dispatch(setSubscriberState({ requiringSettingsLoading: false }));
                    });
          }
     };

     const handleClickBubbleSettings = () => {
          setTimeout(() => {
               if (!tooltipSettings) {
                    setTooltipSettings(true);
               }
          }, 100);
     };

     const tabOverlay = className === "requiring-action" && treeFamilyOfForms.length >= itemsPerPage && countFormRequiring > itemsPerPage;

     return (
          <>
               <div className="title-form-action">
                    <p>You need to take action on these forms to make sure they are tracking correctly.</p>
                    <div className="form-action-settings">
                         <div className="checkbox-header checkbox-ignored-form">
                              <CFormGroup variant="custom-checkbox" inline>
                                   <CInputCheckbox
                                        custom
                                        id={"ignoredForm"}
                                        name={"ignoredForm"}
                                        checked={!!showIgnoredForm}
                                        disabled={isLoadingSettings}
                                        onChange={handleShowIgnoredForm}
                                   />
                                   <CLabel variant="custom-checkbox" htmlFor={"ignoredForm"}>
                                        Show only ignored forms
                                   </CLabel>
                              </CFormGroup>
                         </div>

                         <div className="checkbox-header checkbox-settings">
                              <CIcon icon="cil-settings" cursor="pointer" width={30} onClick={handleClickBubbleSettings} />
                              {tooltipSettings && (
                                   <CCard className="block-tooltip reporting-values-tooltip" ref={statusRefSettings}>
                                        <CIcon icon="arrow-preview" className="arrow-preview"></CIcon>
                                        <div className="block-tooltip-text">
                                             <div className="checkbox-header checkbox-category">
                                                  <CFormGroup variant="custom-checkbox" inline>
                                                       <CInputCheckbox
                                                            custom
                                                            id={"requiring-action"}
                                                            name={"requiring-action"}
                                                            checked={!!applyChild}
                                                            disabled={isLoadingSettings}
                                                            onChange={() => handleApplyChild()}
                                                       />
                                                       <CLabel variant="custom-checkbox" htmlFor={"requiring-action"}>
                                                            Inherit from parent.
                                                       </CLabel>
                                                       <div className="tooltip-wrapper">
                                                            <ClickInfoIconTooltip>
                                                                 Changes you make in the interface are accumulated and must be published to go live on
                                                                 your website.
                                                            </ClickInfoIconTooltip>
                                                       </div>
                                                  </CFormGroup>
                                             </div>
                                             <div className="checkbox-header checkbox-redirect">
                                                  <CFormGroup variant="custom-checkbox" inline>
                                                       <CInputCheckbox
                                                            custom
                                                            id={"applyRedirect"}
                                                            name={"applyRedirect"}
                                                            checked={!!applyRedirect}
                                                            disabled={isLoadingSettings}
                                                            onChange={() => handleApplyRedirect()}
                                                       />
                                                       <CLabel variant="custom-checkbox" htmlFor={"applyRedirect"}>
                                                            Push events after redirect.
                                                       </CLabel>
                                                  </CFormGroup>
                                             </div>
                                        </div>
                                   </CCard>
                              )}
                         </div>
                    </div>
               </div>

               <div
                    className={`table-layout table-requiring-action ${tableClassName} ${
                         treeFamilyOfForms.length === 0 || isLoading ? "no-form-found" : ""
                    }`}
               >
                    <div className="css-table">
                         {className === "viewAllRequiringAction" && requiringFormsLoading && (
                              <div className="table-forms-loading-overlay">
                                   <CenterSpinner />
                              </div>
                         )}
                         <div className="css-table-header">
                              <div className="listener-name">Listener Name</div>
                              <div className="form-id">Form ID</div>
                              <div className="form-location">Form Location</div>
                              <div>Status</div>
                              <div></div>
                              <div></div>
                              {/* <div></div> */}
                         </div>

                         <div
                              className={`css-table-body ${tabOverlay && !isLoading ? "table-overlay" : ""} ${
                                   isLoadingSettings ? "table-loading" : ""
                              }`}
                         >
                              {!isLoading ? (
                                   <>
                                        {treeFamilyOfForms.length > 0 ? (
                                             <>
                                                  {isLoadingSettings && (
                                                       <div className="loading-settings">
                                                            <CSpinner color="primary" />
                                                       </div>
                                                  )}

                                                  {treeFamilyOfForms.map((item, index) => {
                                                       const hasChild = item.hasChild;

                                                       return (
                                                            <React.Fragment key={`requiring-${item.id}`}>
                                                                 {hasChild ? (
                                                                      <MultiFormsRequiring
                                                                           className={className}
                                                                           index={index}
                                                                           formsData={item}
                                                                           applyChild={applyChild}
                                                                           errors={errors}
                                                                           setErrors={setErrors}
                                                                           length={treeFamilyOfForms.length}
                                                                           isRequiring={true}
                                                                           redirectTempFormData={redirectTempFormData}
                                                                      />
                                                                 ) : (
                                                                      <FormRequiringTableRow
                                                                           className={className}
                                                                           data={item}
                                                                           redirect={true}
                                                                           applyChild={applyChild}
                                                                           index={index}
                                                                           requiring={true}
                                                                           errors={errors}
                                                                           setErrors={setErrors}
                                                                           length={treeFamilyOfForms.length}
                                                                           redirectTempFormData={redirectTempFormData}
                                                                      />
                                                                 )}
                                                            </React.Fragment>
                                                       );
                                                  })}
                                             </>
                                        ) : (
                                             <div className="table-body-row">
                                                  <p>No form found!</p>
                                             </div>
                                        )}
                                   </>
                              ) : (
                                   <CenterSpinner />
                              )}
                         </div>
                    </div>

                    {!isLoading && (
                         <>
                              {className === "viewAllRequiringAction" && treeFamilyOfForms.length > 0 && requiringShowPaging && (
                                   <div className="text-center forms-paging">
                                        <CButton
                                             className="btn-paging-forms btn-prev"
                                             disabled={isLoadingSettings || isLoading || requiringFormsLoading || requiringNumberPerpage < 1}
                                             onClick={() => onLoadMore(requiringNumberPerpage - 1)}
                                        >
                                             {"< Prev"}
                                        </CButton>
                                        <CButton
                                             className="btn-paging-forms btn-next"
                                             disabled={isLoadingSettings || isLoading || requiringFormsLoading || requiringDisableNextPage}
                                             onClick={() => onLoadMore(requiringNumberPerpage + 1)}
                                        >
                                             {"Next >"}
                                        </CButton>
                                   </div>
                              )}

                              {tabOverlay && (
                                   <CButton className="btn-show-all" onClick={onClickShowAll}>
                                        See all {requiringCountLoading ? "..." : countFormRequiring} forms
                                        <CIcon className="icon-arrow-bar" icon="iconChevronRight" width={6} height={7} />
                                   </CButton>
                              )}

                              {treeFamilyOfForms.length > 0 && (
                                   <div className="save-changes-button-wrapper">
                                        <CButton color="primary" disabled={isLoadingSettings || submitLoading || formsEdit} onClick={checkValidate}>
                                             {submitLoading ? <span className="dots-waiting">Waiting</span> : "Save Changes"}
                                        </CButton>
                                   </div>
                              )}
                         </>
                    )}
               </div>

               <ConfirmSaveChange
                    show={updateModal}
                    onClose={() => setUpdateModal(false)}
                    isLoading={submitLoading}
                    onAccept={() => onSubmit()}
                    title="Are you sure you want to save?"
               ></ConfirmSaveChange>
          </>
     );
};

export default FormRequiringLayout;
